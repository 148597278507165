import { useReducer } from 'react';
import { waitForGigyaReady } from '../helpers/gigya';

const initialState = { isLoading: false, isReady: false, isError: false };

function reducer(state, action) {
	switch (action.type) {
		case 'loading':
			return { isLoading: true, isReady: false, isError: false };
		case 'ready':
			return { isLoading: false, isReady: true, isError: false };
		case 'error':
			return { isLoading: false, isReady: false, isError: true };
		default:
			throw new Error();
	}
}

export const useGigya = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { isLoading, isReady, isError } = state;

	if (!isLoading && !isReady && !isError) {
		// For if we are gigya login with stubs
		if (window.gigya && window.gigya.cypressMock) {
			setupGigyaForCypress();
		} else {
			setupGigya();
		}
	}

	function setupGigyaForCypress() {
		// eslint-disable-next-line no-console
		dispatch({ type: 'ready' });
		console.log('CYPRESS | Running with stubbed Gigya', window.gigya);
	}

	function setupGigya() {
		dispatch({ type: 'loading' });

		waitForGigyaReady({
			onCreateScriptError: () => dispatch({ type: 'error' }),
		})
			.then(() => {
				dispatch({ type: 'ready' });
			})
			.catch(() => {
				dispatch({ type: 'error' });
			});
	}

	return state;
};
