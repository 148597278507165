import React from 'react';
import Footer from '../footer/Footer';
import cx from 'classnames';
import './Layout.scss';

export default ({ hideFooter, children, fullWidthFooter = false, tvGemistFinalizePage }) => {
	return (
		<div className="layout">
			<div
				className={cx('app-content', {
					['app-content-tvgemist']: tvGemistFinalizePage,
				})}
			>
				{children}
				<div className="layout__push" />
			</div>
			<Footer hideFooter={hideFooter} fullWidthFooter={fullWidthFooter} />
		</div>
	);
};
