import React from 'react';
import { ONBOARDING_EMAIL_VERIFIED_PAYMENT_BUTTON } from '../../../../constants/Copy';
import Button from '../../../common/buttons/onboarding/OnboardingButton';

export const EmailVerifiedProceedButton = ({ proceedUrl, testId }) => {
	return (
		<div className="email-verified__proceed-button">
			<Button testId={testId} href={proceedUrl}>
				{ONBOARDING_EMAIL_VERIFIED_PAYMENT_BUTTON}
			</Button>
		</div>
	);
};
