export const CACHE_POLICIES = {
	NO_CACHE: 'no-cache',
	CACHE_AND_NETWORK: 'cache-and-network',
};

export const NETWORK_STATUS = {
	READY: 7,
};

export const cancellationStatuses = {
	CANCELLATION_BLOCKED: 'CancellationBlocked',
	CANCELLATION_ALLOWED: 'CancellationAllowed',
};
