import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppRoute from './routes/App';
import BedrockRoute from './routes/Bedrock';
import CustomRoute from './routes/Custom';
import OnboardingRoute from './routes/Onboarding';
import PlanRoute from './routes/Plan';

import NotFound from './components/notfound/NotFound';
import OnboardingError from './components/onboarding/error/Error';

import { Contact } from './components/contact/Contact.loadable';
import CompanyInformation from './components/onboarding/company-information/CompanyInformation.loadable';
import { AccountBedrock } from './components/account/account-bedrock/AccountBedrock.loadable';
import { Login } from './components/onboarding/login/Login.loadable';
import Logout from './components/onboarding/logout/Logout.loadable';
import { ForgotPassword } from './components/onboarding/forgot-password/ForgotPassword.loadable';
import Register from './components/onboarding/register/Register.loadable';
import Messages from './components/onboarding/messages/Messages.loadable';
import TrialExpired from './components/onboarding/trial-expired/TrialExpired.loadable';
import { ChangeTier } from './components/account/subscription/changetier/ChangeTier.loadable';
import TierOptions from './components/onboarding/tier-options/TierOptions.loadable';
import { ChangePaymentDetails } from './components/account/change-payment-details/ChangePaymentDetails.loadable';
import PartnerLanding from './components/partner/landing/PartnerLanding';
import Activate from './components/activate/Activate.loadable';
import Finalize from './components/finalize/Finalize.loadable';
import Voucher from './components/voucher/Voucher.loadable';
import EmailVerified from './components/onboarding/register/email-verified/EmailVerified.container';
import Ppe from './components/ppe/Ppe.loadable';

import SoftLanding from './components/onboarding/soft-landing/SoftLanding.loadable';

import {
	requireAuthentication,
	redirect404IfUnauthenticated,
	requireAuthenticationBedrock,
} from './helpers/authentication';

import {
	ROUTE_CONTACT,
	ROUTE_PPE,
	ROUTE_PPE_PAID,
	ROUTE_CONNECT,
	ROUTE_INVOICE,
	ROUTE_LOGIN,
	ROUTE_LOGIN_EMAIL,
	ROUTE_LOGOUT,
	ROUTE_FORGOT_PASSWORD,
	ROUTE_REGISTER,
	ROUTE_REGISTER_EMAIL,
	ROUTE_REGISTER_NULL,
	ROUTE_ERROR,
	ROUTE_MESSAGES,
	ROUTE_NPL,
	ROUTE_NPL_SHORT,
	ROUTE_VRIENDENLOTERIJ,
	ROUTE_TIER_OPTIONS,
	ROUTE_TRIAL_EXPIRED,
	ROUTE_TMOBILE_THUIS,
	ROUTE_PARTNER,
	ROUTE_PARTNER_ACTIVATE,
	ROUTE_ACTIVATE_VOUCHER,
	ROUTE_ACTIVATE_PLAN,
	ROUTE_ACTIVATE_PLAN_STATUS,
	ROUTE_ACTIVATED_PLAN,
	ROUTE_EMAIL_VERIFIED,
	ROUTE_PARTNER_ACTIVATED,
	ROUTE_PARTNER_SMS_CHECK,
	ROUTE_DUTCH_FILM_FESTIVAL,
	ROUTE_DUTCH_FILM_FESTIVAL_SHORT,
	ROUTE_ALBERT_HEIJN,
	ROUTE_ALBERT_HEIJN_SHORT,
	ROUTE_TMOBILE_STANDALONE_REDIRECT,
	ROUTE_TMOBILE_STANDALONE_SHORT_REDIRECT,
	ROUTE_TMOBILE_STANDALONE,
	ROUTE_YOUNG_CAPITAL,
	ROUTE_ZIGGO,
	ROUTE_ACCOUNT_BEDROCK,
	ROUTE_CHANGE_TIER_BEDROCK,
	ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK,
	ROUTE_COMPANY_INFORMATION,
	ROUTE_HOME,
	ROUTE_VRIENDEN_VAN_AMSTEL,
} from './constants/Routes';
import history from './history';

import '../scss/index.scss';
import SmsCheck from './components/onboarding/smscheck/SmsCheck.loadable';
import {
	ALBERT_HEIJN,
	DUTCH_FILM_FESTIVAL,
	NPL,
	VRIENDENLOTERIJ,
	TMOBILE_THUIS,
	YOUNG_CAPITAL,
	ZIGGO,
	VRIENDEN_VAN_AMSTEL,
} from './constants/SoftLanding';
import Connect from './components/connect/Connect.loadable';
import Invoice from './components/account/invoice/Invoice.loadable';

// RedirectWithStatus redirects an user serverside to another route with a specified status (e.g. 301)
function RedirectWithStatus({ from, to, status }) {
	return (
		<Route
			render={({ staticContext }) => {
				// there is no `staticContext` on the client, so
				// we need to guard against that here
				if (staticContext) staticContext.status = status;
				return <Redirect from={from} to={`${to}${history.location.search}`} />;
			}}
		/>
	);
}

const routes = (
	<Switch>
		<CustomRoute path={ROUTE_CONTACT} component={Contact} />

		<BedrockRoute
			path={ROUTE_CHANGE_TIER_BEDROCK}
			component={ChangeTier}
			onEnter={requireAuthenticationBedrock}
		/>
		<BedrockRoute
			path={ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK}
			component={ChangePaymentDetails}
			onEnter={requireAuthenticationBedrock}
		/>
		<BedrockRoute path={ROUTE_INVOICE} component={Invoice} onEnter={requireAuthenticationBedrock} />
		<BedrockRoute
			path={ROUTE_ACCOUNT_BEDROCK}
			component={AccountBedrock}
			onEnter={requireAuthenticationBedrock}
		/>
		<Route path={ROUTE_CONNECT} component={Connect} onEnter={requireAuthentication} />
		<Route path={ROUTE_CONNECT + 'ziggo'} component={Connect} onEnter={requireAuthentication} />

		<RedirectWithStatus from={ROUTE_LOGIN_EMAIL} to={ROUTE_LOGIN} status={301} />
		<RedirectWithStatus from={ROUTE_REGISTER_EMAIL} to={ROUTE_REGISTER} status={301} />
		{/* Hotfix for /aanmelden/null redirects */}
		<RedirectWithStatus from={ROUTE_REGISTER_NULL} to={ROUTE_REGISTER} status={301} />
		<OnboardingRoute path={ROUTE_COMPANY_INFORMATION} component={CompanyInformation} />
		<OnboardingRoute exact path={ROUTE_EMAIL_VERIFIED} component={EmailVerified} pageIsfullWidth />
		<OnboardingRoute path={ROUTE_REGISTER} component={Register} />
		<OnboardingRoute path={ROUTE_LOGIN} component={Login} />
		<OnboardingRoute path={ROUTE_LOGOUT} component={Logout} />
		<OnboardingRoute path={ROUTE_FORGOT_PASSWORD} component={ForgotPassword} />
		<OnboardingRoute path={ROUTE_MESSAGES} component={Messages} pageIsWide />
		<OnboardingRoute
			path={ROUTE_TRIAL_EXPIRED}
			component={TrialExpired}
			onEnter={redirect404IfUnauthenticated}
		/>
		<OnboardingRoute path={ROUTE_PARTNER_SMS_CHECK} component={SmsCheck} />

		<OnboardingRoute path={ROUTE_YOUNG_CAPITAL} component={SoftLanding} {...YOUNG_CAPITAL} />
		<OnboardingRoute
			path={ROUTE_VRIENDEN_VAN_AMSTEL}
			component={SoftLanding}
			{...VRIENDEN_VAN_AMSTEL}
		/>
		<OnboardingRoute path={ROUTE_NPL} component={SoftLanding} {...NPL} />
		<Redirect strict from={ROUTE_NPL_SHORT} to={ROUTE_NPL} />
		<OnboardingRoute path={ROUTE_VRIENDENLOTERIJ} component={SoftLanding} {...VRIENDENLOTERIJ} />
		<Redirect exact strict from={ROUTE_HOME} to={ROUTE_LOGIN} />
		<OnboardingRoute
			path={ROUTE_DUTCH_FILM_FESTIVAL}
			component={SoftLanding}
			{...DUTCH_FILM_FESTIVAL}
		/>
		<Redirect strict from={ROUTE_DUTCH_FILM_FESTIVAL_SHORT} to={ROUTE_DUTCH_FILM_FESTIVAL} />
		<OnboardingRoute path={ROUTE_ALBERT_HEIJN} component={SoftLanding} {...ALBERT_HEIJN} />
		<OnboardingRoute path={ROUTE_ZIGGO} component={SoftLanding} {...ZIGGO} />
		<Redirect strict from={ROUTE_ALBERT_HEIJN_SHORT} to={ROUTE_ALBERT_HEIJN} />
		<OnboardingRoute path={ROUTE_TMOBILE_THUIS} component={SoftLanding} {...TMOBILE_THUIS} />

		<OnboardingRoute path={ROUTE_TIER_OPTIONS} component={TierOptions} pageIsfullWidth />
		<OnboardingRoute path={ROUTE_ERROR} component={OnboardingError} />

		<OnboardingRoute path={ROUTE_ACTIVATE_VOUCHER} component={Voucher} />

		<RedirectWithStatus
			status={301}
			strict
			from={ROUTE_TMOBILE_STANDALONE_REDIRECT}
			to={ROUTE_TMOBILE_STANDALONE}
		/>
		<RedirectWithStatus
			status={301}
			strict
			from={ROUTE_TMOBILE_STANDALONE_SHORT_REDIRECT}
			to={ROUTE_TMOBILE_STANDALONE}
		/>

		<PlanRoute
			path={ROUTE_PARTNER_ACTIVATE}
			component={Activate}
			isThirdPartyVendorRoute
			alwaysEnableOnTest
			pageIsWide
		/>

		<PlanRoute path={ROUTE_PARTNER_ACTIVATED} component={Finalize} isThirdPartyVendorRoute />

		<PlanRoute path={ROUTE_PARTNER} component={PartnerLanding} isThirdPartyVendorRoute />

		<PlanRoute path={ROUTE_ACTIVATE_PLAN_STATUS} component={Activate} pageIsWide />

		<PlanRoute path={ROUTE_ACTIVATE_PLAN} component={Activate} pageIsWide />

		<PlanRoute path={ROUTE_ACTIVATED_PLAN} component={Finalize} />

		<CustomRoute path={ROUTE_PPE} component={Ppe} hideFooter />
		<CustomRoute path={ROUTE_PPE_PAID} component={Ppe} hideFooter />

		<AppRoute component={NotFound} />
	</Switch>
);

export default routes;

// to prevent 2 instances of helmet we keep a reference here that can be used for SSR.
// See https://github.com/nfl/react-helmet/issues/125 for more info
export { default as Helmet } from 'react-helmet';
