import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import queryOpenPayments from '../graphql/queryOpenPayments.graphql';
import queryInvoices from '../graphql/queryInvoices.graphql';
import { getPaymentClient } from '../api/paymentService';
import { useEffect, useRef } from 'react';
import { PAYMENT_STATUS } from '../components/account/paymentReminder/PaymentReminder';
import { CACHE_POLICIES, NETWORK_STATUS } from '../constants/GraphQL';
import { getBillingClient } from '../api/billingService';
import { hasValidVideolandCookie } from '../helpers/authentication';

const videolandPaymentApiClient = getPaymentClient();
const billingClient = getBillingClient();

export const useInvoices = createUseInvoices({ useSelector });

export function createUseInvoices({ useSelector }) {
	return function useInvoices() {
		const openPaymentsStore = useSelector((state) => state.subscription.openPayments);
		const openPaymentsIsPristine = openPaymentsStore.pristine;
		const openPayments = useRef();
		const isLoggedIn = hasValidVideolandCookie();

		const {
			loading: invoicesLoading,
			error,
			data: invoicesData,
			refetch: refetchInvoices,
			startPolling: startPollingInvoices,
			stopPolling: stopPollingInvoices,
		} = useQuery(queryInvoices, {
			client: billingClient,
			fetchPolicy: CACHE_POLICIES.CACHE_AND_NETWORK,
			notifyOnNetworkStatusChange: true,
			skip: !isLoggedIn,
		});

		const {
			data: openPaymentsData,
			loading: openPaymentsLoading,
			refetch: refetchOpenPayments,
			networkStatus: networkStatusOpenPayments,
		} = useQuery(queryOpenPayments, {
			client: videolandPaymentApiClient,
			fetchPolicy: CACHE_POLICIES.CACHE_AND_NETWORK,
			skip: openPaymentsIsPristine && !isLoggedIn,
			notifyOnNetworkStatusChange: true,
		});

		useEffect(() => {
			if (!openPayments.current && !openPaymentsLoading && openPaymentsData) {
				openPayments.current = openPaymentsData.me.openPayments;
			}
		}, [openPaymentsLoading, openPaymentsData]);

		useEffect(() => {
			if (
				networkStatusOpenPayments === NETWORK_STATUS.READY &&
				openPaymentsData &&
				openPayments.current
			) {
				const paymentExists = (id) => {
					let exists = false;

					openPaymentsData.me.openPayments.forEach(function (payment) {
						if (payment.id === id) exists = true;
					});

					return exists;
				};

				let paidPayments = [];
				openPayments.current.forEach((payment) => {
					!paymentExists(payment.id) && paidPayments.push(payment);
				});

				if (paidPayments.length > 0) {
					openPayments.current = [
						...paidPayments.map((payment) => ({ ...payment, status: PAYMENT_STATUS.PAID })),
						...openPaymentsData.me.openPayments,
					];
				} else {
					openPayments.current = [...openPaymentsData.me.openPayments];
				}
			}
		}, [networkStatusOpenPayments, openPaymentsData]);

		const refetch = () => {
			refetchInvoices();
			refetchOpenPayments();
		};

		function returnOpenPayments() {
			if (
				(!openPaymentsData?.me.openPayments.length && openPayments.current?.length > 0) ||
				(openPaymentsData?.me.openPayments &&
					openPayments.current?.length > openPaymentsData?.me.openPayments.length)
			) {
				return openPayments.current;
			} else {
				return openPaymentsData?.me.openPayments;
			}
		}

		return {
			startPollingInvoices,
			stopPollingInvoices,
			openPayments: returnOpenPayments(),
			invoices: invoicesData?.invoices,
			refetch: () => refetch(),
			loading: invoicesLoading,
			error,
		};
	};
}
