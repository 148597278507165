/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button, { BUTTON_KIND_TEXT } from '../buttons/onboarding/OnboardingButton';
import Heading from '../heading/Heading';
import './FullPageMessage.scss';
import isString from 'lodash/isString';

const FullPageMessage = ({ button, className, image, title, description, textLink }) => {
	const fullPageMessageWithImage = !!image;

	function renderDisplayOptimizedTitle() {
		let displayOptimizedTitle = '';
		if (isString(title)) {
			displayOptimizedTitle = title.replace('-', '&#8209;'); // Replace regular dash with non-breaking dash
		}
		return <span dangerouslySetInnerHTML={{ __html: displayOptimizedTitle }} />;
	}

	return (
		<div
			className={cx('full-page-message', {
				[className]: className,
				['full-page-message--with-image']: fullPageMessageWithImage,
			})}
		>
			{fullPageMessageWithImage ? (
				<img
					className="onboarding__image"
					src={image}
					alt={title}
					data-test="full-page-message-image"
				/>
			) : null}
			<Heading className="full-page-message__heading" testId="full-page-message-title">
				{renderDisplayOptimizedTitle()}
			</Heading>
			<div className="onboarding__text" data-test="full-page-message-description">
				{description}
			</div>
			{button && (
				<p className="onboarding__text">
					<Button
						onClick={button.onClick}
						href={button.href}
						loading={button.loading}
						testId="full-page-message-button"
						testValue={button.testValue}
					>
						{button.text}
					</Button>
				</p>
			)}
			{textLink && (
				<p className="onboarding__text">
					<Button
						className="onboarding__link accent"
						kind={BUTTON_KIND_TEXT}
						onClick={textLink.onClick}
						href={textLink.href}
						testId="full-page-message-text-link"
						testValue={textLink.testValue}
					>
						{textLink.text}
					</Button>
				</p>
			)}
		</div>
	);
};

const validateButtonClickValue = (props, propName, componentName) => {
	if (!props.onClick && !props.href) {
		return new Error(`One of props 'onClick' or 'href' was not specified in '${componentName}'.`);
	}

	const types = {
		href: PropTypes.string,
		onClick: PropTypes.func,
	};

	PropTypes.checkPropTypes(types, props, propName, componentName);
};

const buttonType = PropTypes.shape({
	loading: PropTypes.bool,
	onClick: validateButtonClickValue,
	href: validateButtonClickValue,
	testValue: PropTypes.string,
	text: PropTypes.string.isRequired,
});

FullPageMessage.propTypes = {
	className: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.node.isRequired,
	button: buttonType,
	textLink: buttonType,
};

export default FullPageMessage;
