import { PAYPAL as PAYPAL_COPY, CREDITCARD as CREDITCARD_COPY, IDEAL as IDEAL_COPY } from './Copy';

export const DEFAULT_RATEPLAN_SLUG = '2-weken-videoland';
export const WINBACK_RATEPLAN_SLUG = '1-maand-videoland-evergreen';
export const FRAUDULENT_BEHAVIOR_RATEPLAN_SLUG = WINBACK_RATEPLAN_SLUG;

export const IDEAL = IDEAL_COPY.toUpperCase();
export const PAYPAL = PAYPAL_COPY.toUpperCase();
export const CREDITCARD = CREDITCARD_COPY.toUpperCase();

// These rateplans will bypass the product availability check, enabling us to present the user with a
// rateplan even if Smartvod/Zuora says it is not allowed.
// (yes, this is really dirty)
export const ALWAYS_AVAILABLE_RATEPLANS = [
	'termed-paid',
	'2-weken-videoland-t-2bb',
	'1-maand-videoland-premium-voucher',
	'upgrade-premium-naar-videoland',
	'3-maanden-videoland-coolblue',
	'3-maanden-videoland-coolblue-tv',
	'6-maanden-videoland-coolblue-tv',
	'3-maanden-videoland-coolblue-tv-2019',
	'6-maanden-videoland-coolblue-tv-2019',
	'2-maanden-videoland-huishoudbeurs',
];

export const CREDIT_CARD_BRANDS = {
	MASTERCARD: 'Mastercard',
	VISA: 'Visa',
};

export const PAYMENT_METHOD_GRAPHQL = {
	IDEAL: 'IdealPaymentDetails',
	PAYPAL: 'PayPalPaymentDetails',
	CREDIT_CARD: 'CreditCardPaymentDetails',
};

export const DEBT_STATUS = {
	NO_DEBT: 'NO_DEBT',
	BAD_DEBT_ON_THIS_ACCOUNT: 'BAD_DEBT_ON_THIS_ACCOUNT',
	BAD_DEBT_ON_OTHER_ACCOUNT: 'BAD_DEBT_ON_OTHER_ACCOUNT',
};

export const IDEAL_OPTIONS = [
	{ id: 'ing', image: 'ing.svg', bic: 'INGBNL2A' },
	{ id: 'rabobank', image: 'rabobank.svg', bic: 'RABONL2U' },
	{ id: 'abn', image: 'abnamro.svg', bic: 'ABNANL2A' },
	{ id: 'asnbank', image: 'asnbank.svg', bic: 'ASNBNL21' },
	{ id: 'regiobank', image: 'regiobank.svg', bic: 'RBRBNL21' },
	{ id: 'knab', image: 'knabbank.svg', bic: 'KNABNL2H' },
	{ id: 'sns', image: 'snsbank.svg', bic: 'SNSBNL2A' },
	{ id: 'vanlanschot', image: 'vanlanschot.svg', bic: 'FVLBNL22' },
	{ id: 'triodos', image: 'triodosbank.svg', bic: 'TRIONL2U' },
	{ id: 'bunq', image: 'bunq.svg', bic: 'BUNQNL2A' },
	{ id: 'Moneyou', image: 'moneyou.svg', bic: 'MOYONL21' },
	{ id: 'Handelsbanken', image: 'handelsbanken.svg', bic: 'HANDNL2A' },
	{ id: 'Revolut', image: 'revolt.svg', bic: 'REVOLT21' },
];

export const IDEAL_OPTIONS_MAP = IDEAL_OPTIONS.reduce(
	(acc, val) => ({ ...acc, [val.bic]: val }),
	{}
);

export const CREDITCARD_OPTIONS = [
	{
		idChangePaymentDetail: 'MASTER_CARD',
		idPurchase: 'MASTER_CARD',
		title: CREDIT_CARD_BRANDS.MASTERCARD,
		image: 'mastercard.svg',
	},
	{
		idChangePaymentDetail: 'VISA',
		idPurchase: 'VISA',
		title: CREDIT_CARD_BRANDS.VISA,
		image: 'visa.svg',
	},
];

export const CREDITCARD_ICONS = {
	MASTER_CARD: 'mastercard.svg',
	VISA: 'visa.svg',
};

export const PAYMENT_METHODS = [
	{ id: IDEAL, title: IDEAL_COPY, image: 'ideal.svg', graphql_type: PAYMENT_METHOD_GRAPHQL.IDEAL },
	{
		id: PAYPAL,
		title: PAYPAL_COPY,
		image: 'paypal.svg',
		graphql_type: PAYMENT_METHOD_GRAPHQL.PAYPAL,
	},
	{
		id: CREDITCARD,
		title: CREDITCARD_COPY,
		image: 'creditcard.svg',
		graphql_type: PAYMENT_METHOD_GRAPHQL.CREDIT_CARD,
	},
];

export const PAYMENT_DETAILS_STATUS = {
	PENDING: 'PENDING',
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
	CANCELLED: 'CANCELLED',
};

export const PAY_PPE_RESPONSE_TYPE = {
	SUCCESFUL_PAY_PPE_RESPONSE: 'SuccessfulPayPpeResponse',
	PPE_PAYMENT_IS_IN_PROGRESS: 'PpePaymentIsInProgress',
	PPE_IS_PAID_OR_DOESNT_EXIST: 'PpeIsPaidOrDoesntExist',
};

export const PPE_PAYMENT_STATUS = {
	CREATED: 'CREATED',
	PAID: 'PAID',
	CANCELLED: 'CANCELLED',
};

export const PPE_TRANSACTION_STATUS = {
	CREATED: 'Created',
	SUCCESS: 'Success',
	PENDING: 'Pending',
	FAILED: 'Failed',
	REJECTED: 'Rejected',
	CANCELLED: 'Cancelled',
};

export const TRANSACTION_STATUS_VALUES = {
	INITIALIZED: 'INITIALIZED',
	IN_PROGRESS: 'IN_PROGRESS',
	FINISHED: 'FINISHED',
	FRAUD: 'FRAUD',
	PAYMENT_FAILED: 'PAYMENT_FAILED',
	PAYMENT_CANCELLED: 'PAYMENT_CANCELLED',
	PAYMENT_REJECTED: 'PAYMENT_REJECTED',
	BLOCKED: 'BLOCKED',
};

export const TRANSACTION_ERROR_CODES = {
	THERE_IS_A_RECENT_PLAN_PURCHASE: 'THERE_IS_A_RECENT_PLAN_PURCHASE',
	PLAN_NOT_FOUND: 'PLAN_NOT_FOUND',
	THERE_IS_ANOTHER_ACTIVE_SUBSCRIPTION: 'THERE_IS_ANOTHER_ACTIVE_SUBSCRIPTION',
	BLACK_FRIDAY_NOT_AVAILABLE: 'BLACK_FRIDAY_NOT_AVAILABLE',
};

export const TRANSACTION_STATUS_SLUGS = {
	[TRANSACTION_STATUS_VALUES.PAYMENT_FAILED]: 'transactie-mislukt',
	[TRANSACTION_STATUS_VALUES.PAYMENT_CANCELLED]: 'transactie-geannuleerd',
	[TRANSACTION_STATUS_VALUES.PAYMENT_REJECTED]: 'transactie-afgewezen',
};

export const TRANSACTION_PROPERTIES = {
	PURCHASE: {
		METHOD_SLUG: {
			ID: 'purchase',
			URL: 'betaling',
		},
		STATE_KEY: 'purchaseState',
	},
	VOUCHER_REDEMPTION: {
		METHOD_SLUG: {
			ID: 'voucher',
			URL: 'voucher',
		},
		STATE_KEY: 'voucherRedemptionState',
	},
};
