import reduce, { defaultState, errorState, pendingState, successState } from '../../helpers/redux';
import {
	isActiveNPLSubscription,
	isConvertedToTermedUser,
	hasPendingSuspension,
} from '../../helpers/state';
import {
	SUBSCRIPTION_CANCEL,
	SUBSCRIPTION_REACTIVATE,
	SUBSCRIPTIONS_FETCH,
	SET_SUBSCRIPTION,
	SET_SUBSCRIPTION_TIER,
	SUBSCRIPTION_FETCH_TIER,
	SUBSCRIPTION_CHANGE_TIER,
	FETCH_OPEN_PAYMENTS,
	SUBSCRIPTION_CLEAR,
} from '../../constants/ActionTypes';

const initialState = {
	load: defaultState,
	cancel: defaultState,
	reactivate: defaultState,
	invoices: {
		...defaultState,
		data: [],
	},
	details: {
		...defaultState,
		pristine: true,
		data: null,
	},
	tier: {
		...defaultState,
		pristine: true,
	},
	changeTier: defaultState,
	openPayments: {
		...defaultState,
		pristine: true,
		data: [],
	},
	isActiveNPLSubscription: false,
	isConvertedToTermedUser: false,
};

const actionsMap = {
	[SUBSCRIPTION_CANCEL + '_PENDING']: () => ({ cancel: pendingState }),
	[SUBSCRIPTION_CANCEL + '_SUCCESS']: () => ({ cancel: successState, reactivate: defaultState }),
	[SUBSCRIPTION_CANCEL + '_ERROR']: () => ({ cancel: errorState }),

	[SUBSCRIPTION_REACTIVATE + '_PENDING']: () => ({ reactivate: pendingState }),
	[SUBSCRIPTION_REACTIVATE + '_SUCCESS']: () => ({
		reactivate: successState,
		cancel: defaultState,
	}),
	[SUBSCRIPTION_REACTIVATE + '_ERROR']: () => ({ reactivate: errorState }),
	[SUBSCRIPTION_FETCH_TIER + '_SUCCESS']: (state, { payload }) => ({
		tier: { ...successState, pristine: false, data: payload.data.me },
	}),
	[SUBSCRIPTIONS_FETCH + '_PENDING']: (state) => ({
		pristine: false,
		details: { ...state.details, ...pendingState },
	}),
	[SUBSCRIPTIONS_FETCH + '_ERROR']: () => ({ details: errorState, pristine: false }),
	[SUBSCRIPTIONS_FETCH + '_SUCCESS']: (state, { payload }) => ({
		isActiveNPLSubscription: isActiveNPLSubscription(payload.data.me.subscription),
		isConvertedToTermedUser: isConvertedToTermedUser(payload.data.me.subscription),
		hasPendingSuspension: hasPendingSuspension(payload.data.me.subscription),
		details: { ...successState, data: payload.data.me?.subscription, pristine: false },
	}),
	[SET_SUBSCRIPTION]: (state, { payload }) => ({
		isActiveNPLSubscription: isActiveNPLSubscription(payload.me?.subscription),
		isConvertedToTermedUser: isConvertedToTermedUser(payload.me?.subscription),
		hasPendingSuspension: hasPendingSuspension(payload.me?.subscription),
		details: { ...successState, data: payload.me?.subscription, pristine: false },
	}),
	[SUBSCRIPTION_CLEAR]: () => initialState,
	[SUBSCRIPTION_FETCH_TIER + '_PENDING']: () => ({ tier: { ...pendingState, pristine: false } }),
	[SUBSCRIPTION_FETCH_TIER + '_ERROR']: () => ({ tier: { ...errorState, pristine: false } }),
	[SUBSCRIPTION_FETCH_TIER + '_SUCCESS']: (state, { payload }) => ({
		tier: { ...successState, pristine: false, data: payload.data.me },
	}),
	[SET_SUBSCRIPTION_TIER]: (state, { payload }) => ({
		tier: { ...successState, pristine: false, data: payload.me },
	}),
	[SUBSCRIPTION_CHANGE_TIER + '_PENDING']: () => ({ changeTier: pendingState }),
	[SUBSCRIPTION_CHANGE_TIER + '_ERROR']: () => ({ changeTier: errorState }),
	[SUBSCRIPTION_CHANGE_TIER + '_SUCCESS']: () => ({ changeTier: successState }),

	[FETCH_OPEN_PAYMENTS + '_PENDING']: (state) => ({
		openPayments: { ...pendingState, pristine: false, data: state.openPayments.data },
	}),
	[FETCH_OPEN_PAYMENTS + '_ERROR']: () => ({
		openPayments: { ...errorState, pristine: false, data: [] },
	}),
	[FETCH_OPEN_PAYMENTS + '_SUCCESS']: (state, body) => ({
		openPayments: {
			...successState,
			pristine: false,
			data: body.payload.data.me.openPayments,
		},
	}),
};

export default reduce(initialState, actionsMap);
