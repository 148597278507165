import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../../common/icons/Icon';
import Button, { BUTTON_KIND_SECOND } from '../buttons/onboarding/OnboardingButton';
import IconButton from '../buttons/icon/IconButton';
import './Notification.scss';
import { NOTIFICATION_HIDE_BUTTON } from '../../../constants/Copy';

export const NOTIFICATION_TYPE_INFORMATION = 'information';
export const NOTIFICATION_TYPE_WARNING = 'warning';
export const NOTIFICATION_TYPE_ERROR = 'error';
export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_TRANSPARENT = 'transparent';

export const NOTIFICATION_APPEARANCE_WIDE = 'wide';

const ICON_SUCCESS = 'checkmark';
const ICON_WARNING = 'exclamation_mark';

const Notification = ({
	appearance,
	className,
	button,
	description,
	showCloseButton,
	title,
	onClose,
	onCloseButtonClick,
	type = NOTIFICATION_TYPE_INFORMATION,
	visible = false,
	testId = 'notification',
	hasIcon = true,
}) => {
	const [isVisible, setIsVisible] = useState(visible);

	useEffect(() => {
		if (isVisible && onClose) {
			return onClose;
		}
	}, [isVisible, onClose]);

	useEffect(() => {
		setIsVisible(visible);
	}, [visible]);

	const notificationClassNames = cx('notification', {
		[className]: !!className,
		[`notification--${type}`]: !!type,
		[`notification--${appearance}`]: !!appearance,
		['notification--button']: !!button,
		['notification--close-button']: !!showCloseButton,
		[`notification__no-icon`]: !hasIcon,
	});

	const handleCloseButtonClick = () => {
		if (onCloseButtonClick) {
			onCloseButtonClick();
		}
		setIsVisible(false);
	};

	const buttonIsVisible = button?.text && button?.onClick;

	return !isVisible || (!title && !description) ? null : (
		<section className={notificationClassNames} role="alert" data-test={testId} data-type={type}>
			{hasIcon && renderIcon(type)}
			<div className="notification__body">
				{title ? renderTitle(title) : null}
				{description ? renderDescription(description) : null}
			</div>

			{(buttonIsVisible || showCloseButton) && (
				<div className="notification__actions">
					{buttonIsVisible && renderButton(button)}
					{showCloseButton && renderCloseButton(handleCloseButtonClick)}
				</div>
			)}
		</section>
	);
};

const renderIcon = (type) => {
	const iconName = type === NOTIFICATION_TYPE_SUCCESS ? ICON_SUCCESS : ICON_WARNING;

	return (
		<span className="notification__icon-wrapper" data-test="notification-icon" role="img">
			<Icon name={iconName} viewBox="2 2 20 20" />
		</span>
	);
};

const renderTitle = (title) => (
	<header>
		<h4 className="notification__title" data-test="notification-title">
			{title}
		</h4>
	</header>
);

const renderDescription = (description) => (
	<p className="notification__description" data-test="notification-description">
		{description}
	</p>
);

const renderButton = ({ text, onClick, testId = 'notification-button' }) => (
	<Button
		className="notification__button"
		onClick={() => onClick()}
		testId={testId}
		kind={BUTTON_KIND_SECOND}
	>
		{text}
	</Button>
);

const renderCloseButton = (handleCloseButtonClick) => (
	<IconButton
		className="notification__button--close"
		iconName="times"
		kind={BUTTON_KIND_SECOND}
		onClick={handleCloseButtonClick}
		testId="notification-close-button"
		text={NOTIFICATION_HIDE_BUTTON}
	/>
);

Notification.propTypes = {
	appearance: PropTypes.string,
	button: PropTypes.object,
	className: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
	onClose: PropTypes.func,
	onCloseButtonClick: PropTypes.func,
	showCloseButton: PropTypes.bool,
	title: PropTypes.string,
	type: PropTypes.string,
	visible: PropTypes.bool,
};

export default Notification;
