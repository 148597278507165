import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { isConnectPartnerProcessingRoute } from '../../../../helpers/connectPartner';

export default ({ activeStep, partiallyCompleted }) => {
	const redirectUrl = useSelector((state) => state.authentication.redirectUrl);
	if (isConnectPartnerProcessingRoute(redirectUrl)) {
		return null;
	}
	const Component = loadable(() => import('./OnboardingProgress'));
	return <Component activeStep={activeStep} partiallyCompleted={partiallyCompleted} />;
};
