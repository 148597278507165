import reduce, { defaultState, errorState } from '../../helpers/redux';
import {
	DEVICES_LOAD,
	DEVICE_ADD,
	DEVICE_REMOVE,
	DEVICE_ERROR_RESET,
} from '../../constants/ActionTypes';

const initialState = {
	...defaultState,
	all: [],
	add: false,
	remove: false,
	connectableDevices: null,
};

const actionsMap = {
	[DEVICES_LOAD + '_SUCCESS']: (state, action) => ({ all: action.payload }),

	[DEVICE_ADD + '_SUCCESS']: () => ({ ...defaultState, add: true, remove: false }),
	[DEVICE_ADD + '_ERROR']: () => ({ ...errorState, add: false, remove: false }),

	[DEVICE_REMOVE + '_SUCCESS']: () => ({ ...defaultState, remove: true, add: false }),
	[DEVICE_REMOVE + '_ERROR']: () => ({ ...errorState, add: false, remove: false }),

	[DEVICE_ERROR_RESET]: () => ({ ...defaultState, add: false, remove: false }),
};

export default reduce(initialState, actionsMap);
