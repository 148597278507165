import './readAndSetConfig';
import './polyfills/delayed-scroll-restoration';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { detectTouchEvents } from './clientCapabilities';
import {
	installFocusVisible,
	installGoogleTagManager,
	installSmartX,
	installUsabilla,
} from './scriptloader';
import { ConnectedRouter } from 'connected-react-router';
import { App } from '../common/components/app/App';
import history from '../common/history';
import store from '../common/store';
import { loadableReady } from '@loadable/component';
import { reportError } from '../common/helpers/realUserMonitoring';
import { isSafari } from '../common/helpers/browser';

if (__ANALYTICS__) {
	window.dataLayer = [
		{
			'gtm.start': new Date().getTime(),
			'event': 'gtm.js',
		},
	];

	((history) => {
		const { pushState } = history;
		history.pushState = function ({ state = {} }, title, url) {
			state.analytics = state.analytics || {};
			// Set the `pageUrl` to DRY anywhere `state.analytics` is used in `<Link>` components.
			// For events, `pageUrl` should reflect event location, otherwise link target.
			state.analytics.pageUrl = 'event' in state.analytics ? location.href : location.origin + url;
			return pushState.apply(history, arguments);
		};
	})(window.history);
}

const renderApp = (renderer) =>
	renderer(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>,
		document.querySelector('main')
	);

if (__DEBUG__) {
	if (module.hot) {
		loadableReady(() => {
			renderApp(render);
		});
	}
} else {
	loadableReady(() => {
		renderApp(hydrate);
	});
}

detectTouchEvents();

(async () => {
	const scriptsArray = [installUsabilla(), installGoogleTagManager(), installSmartX()];
	if (isSafari) {
		scriptsArray.push(installFocusVisible());
	}

	try {
		await Promise.all(scriptsArray);
	} catch (e) {
		reportError(e);
	}
})();
