import React from 'react';
import { NOTFOUND_DESCRIPTION, NOTFOUND_TITLE, NOTFOUND_TO_HOME } from '../../constants/Copy';
import { PAGE_NOT_FOUND } from '../../constants/ErrorTypes';
import { markAsErrorPage } from '../../helpers/realUserMonitoring';
import Status from '../common/status/Status';

const NotFound = () => {
	markAsErrorPage({ code: 404, message: PAGE_NOT_FOUND });

	return (
		<Status code={404}>
			<div className="page-content">
				<section className="error__main">
					<header>
						<h1>{NOTFOUND_TITLE}</h1>
					</header>
					<p className="error__message">{NOTFOUND_DESCRIPTION}</p>
					<a href="/" className="vl-button vl-button--white" data-test="page-not-found-button">
						{NOTFOUND_TO_HOME}
					</a>
				</section>
			</div>
		</Status>
	);
};

export default NotFound;
