import {
	DEFER_LOGIN,
	DEFER_LOGIN_RESET,
	ACCOUNT_RESET_PASSWORD,
	ACCOUNT_CHECK_RESET_TOKEN,
	ACCOUNT_UPDATE_PASSWORD,
	GIGYA_LOGIN,
	GIGYA_LOGOUT,
	GIGYA_CLOSE_POPUP,
	GIGYA_LINK_ACCOUNT,
	VIDEOLAND_LOGIN,
	GIGYA_GET_ACCOUNT_INFO,
	OVERWRITE_REDIRECT_URL,
	CLEAR_REDIRECT_URL,
	OVERWRITE_UID,
	IDENTITY_SERVICE_LOGOUT,
	ACCOUNT_CLEAR,
	GIGYA_VERIFY_LOGIN,
} from '../constants/ActionTypes';
import {
	ROUTE_HOME,
	ROUTE_REGISTER,
	ROUTE_VERIFY_EMAIL,
	ROUTE_REGISTER_INCOMPLETE,
	ROUTE_LOGIN_LINK_NOTIFICATION,
	ROUTE_FORGOT_PASSWORD_SENT,
	ROUTE_THIRD_PARTY_COOKIES,
	ROUTE_FORGOT_PASSWORD_NEW,
	ROUTE_LOGIN_EMAIL,
	ROUTE_LOGIN,
	ROUTE_TIER_OPTIONS,
} from '../constants/Routes';
import history from '../../common/history';
import { analyticsPush } from '../actions/applicationActions';
import { getEndpointUrl } from '../config';
import { AUTH_COOKIE, AUTH_DOMAIN } from '../config';
import {
	EVT_LOGIN_ERROR,
	EVT_LOGIN_SUCCESS,
	EVT_LOGOUT_SUCCESS,
	EVT_REQ_RESET_LINK,
} from '../constants/EventTypes';
import { cookie } from '../helpers/storage';
import { redirectInternal } from '../helpers/redirect';
import { isPikselLogin } from '../helpers/piksel';
import { getRateplanUrl } from '../helpers/url';
import {
	ACCOUNT_NOT_FINALIZED,
	ACCOUNT_INCOMPLETE,
	EXISTING_ACCOUNT,
	UNVERIFIED_ACCOUNT,
	UNAUTHORIZED_USER,
	THIRD_PARTY_COOKIES_BLOCKED,
} from '../constants/ErrorTypes';
import { reportCustomError, reportError } from '../helpers/realUserMonitoring';
import { COOKIE_NAMES } from '../constants/Cookies';
import { autoLoginBR } from '../hooks/useRedirectUser';
import { isRouteAccessible, getPathnameFromRoute } from '../helpers/routes';
import { fetchSubscription, subscriptionClear } from './profileActions';

export const login = (authData) => (dispatch, getState) => {
	const { redirectUrl, expires } = getState().authentication;
	const decodedRedirectUrl = getPathnameFromRoute(decodeURIComponent(redirectUrl));

	return dispatch(gigyaLogin(authData))
		.then(() => dispatch(videolandLoginFlow()))
		.then(() =>
			dispatch(
				analyticsPush({
					event: EVT_LOGIN_SUCCESS,
					loginType: 'e-mail',
					loginMethod: 'password',
				})
			)
		)
		.then(() => dispatch(fetchSubscription()))

		.then(() => {
			const hasActiveSubscription =
				getState().subscription?.details?.data?.status?.toUpperCase() === 'ACTIVE';

			if (isRouteAccessible(decodedRedirectUrl, hasActiveSubscription)) {
				return redirectInternal(decodedRedirectUrl);
			}

			if (!hasActiveSubscription) {
				return history.push(ROUTE_TIER_OPTIONS);
			}

			return autoLoginBR(expires);
		})
		.catch((err) => {
			const {
				authentication: { errorCode, gigyaErrorCode },
			} = getState();
			dispatch(
				analyticsPush({
					event: EVT_LOGIN_ERROR,
					errorCode,
					gigyaErrorCode,
				})
			);
			reportError(err);
			return redirectByLoginError(err, getState);
		});
};

export const redirectHandler = (redirectUrl) => {
	const decodedRedirectUrl = decodeURIComponent(redirectUrl);

	if (!redirectUrl || !isRouteAccessible(decodedRedirectUrl)) {
		return history.push(ROUTE_LOGIN);
	}

	redirectInternal(decodedRedirectUrl);
};

export const logout = () => (dispatch) => {
	const { XLTOVL_COOKIE } = COOKIE_NAMES;
	const vlHash = cookie.get(AUTH_COOKIE);

	return dispatch(gigyaLogout())
		.then(() => {
			if (!vlHash || !isPikselLogin(vlHash)) return Promise.resolve();
			return dispatch(identityServiceLogout(vlHash));
		})
		.then(() => clearAllCookies())
		.then(() => dispatch(accountClear()))
		.then(() => dispatch(subscriptionClear()))
		.then(() =>
			dispatch(
				analyticsPush({
					event: EVT_LOGOUT_SUCCESS,
				})
			)
		)
		.catch(reportError)
		.then(() => {
			cookie.remove(XLTOVL_COOKIE);

			const url = new URL(document.location.href);
			const params = new URLSearchParams(url.search);
			const redirectUrl = params.get('redirect');
			redirectHandler(redirectUrl);
		});
};

export const socialLogin = (provider) => (dispatch, getState) =>
	dispatch(gigyaSocialLogin(provider))
		.then(() => dispatch(videolandLoginFlow()))
		.then(() =>
			dispatch(
				analyticsPush({
					event: EVT_LOGIN_SUCCESS,
					loginType: 'social',
					loginMethod: provider,
				})
			)
		)
		.then(() => history.push(ROUTE_HOME))
		.catch((err) => {
			reportError(err);
			return redirectByLoginError(err, getState);
		});

export const verifyEmailWithGigya = () => (dispatch, getState) =>
	dispatch(gigyaGetAccountInfo())
		.then(() => dispatch(videolandLogin()))
		.then(() => dispatch(setVideolandCookie()))
		.catch((err) => {
			reportError(err);
			return redirectByLoginError(err, getState);
		});

export const videolandLoginFlow = () => (dispatch) =>
	dispatch(overwriteUID())
		.then(() => dispatch(videolandLogin()))
		.then(() => dispatch(setVideolandCookie()));

export const redirectByLoginError = (err, getState) => {
	const {
		authentication: { error, errorCode },
	} = getState();

	if (!error) return;
	if (errorCode === UNVERIFIED_ACCOUNT) return history.push(ROUTE_VERIFY_EMAIL);
	if (errorCode === THIRD_PARTY_COOKIES_BLOCKED) return history.push(ROUTE_THIRD_PARTY_COOKIES);
	if (errorCode === ACCOUNT_NOT_FINALIZED) return history.push(ROUTE_REGISTER);
	if (errorCode === ACCOUNT_INCOMPLETE) return history.push(ROUTE_REGISTER_INCOMPLETE);
	if (errorCode === EXISTING_ACCOUNT) return history.push(ROUTE_LOGIN_LINK_NOTIFICATION);
	if (errorCode === UNAUTHORIZED_USER) return history.push(ROUTE_REGISTER);

	reportCustomError({
		name: 'redirect_by_login_error',
		error: errorCode,
		message: 'errorCode is not mapped',
	});
	throw new Error(errorCode);
};

const overwriteUID = () => (dispatch, getState) => {
	const {
		authentication: { gigyaUser },
	} = getState();
	const { UID, profile } = gigyaUser;
	if (UID && UID.length > 32 && profile.email) {
		return dispatch(overwriteUIDRequest({ UID }));
	}
	return Promise.resolve();
};

const videolandLogin = () => (dispatch, getState) => {
	const {
		authentication: { gigyaUser },
	} = getState();
	const { UID, UIDSignature, signatureTimestamp } = gigyaUser;
	return dispatch(videolandLoginRequest({ UID, UIDSignature, signatureTimestamp }));
};

export const setVideolandCookie = () => (dispatch, getState) => {
	const {
		authentication: { vlId, expires },
	} = getState();
	cookie.set(AUTH_COOKIE, vlId, {
		domain: AUTH_DOMAIN,
		expires: new Date(expires),
		httpOnly: false, // we sometimes need to read this cookie to use the token for some requests
		path: '/',
		secure: true,
	});
};

export const clearAllCookies = () => {
	return removeAllCookies();
};

function removeAllCookies() {
	const consentCookieNames = ['OptanonConsent', 'OptanonAlertBoxClosed', 'eupubconsent-v2'];

	const cookieOptions = {
		path: '/',
	};

	if (!window.cypressLooseCookies) {
		cookieOptions.domain = AUTH_DOMAIN;
	}

	const cookies = cookie.getAll();

	return Object.keys(cookies).forEach((cookieName) => {
		if (consentCookieNames.includes(cookieName)) return;
		return cookie.remove(cookieName, cookieOptions);
	});
}

/**
 * Some intern/external applications cannot handle long id's and need to be converted
 * to a shorter UID. Ideally this logic is performed in a backend API in the future.
 */
const overwriteUIDRequest = (data) => ({
	type: OVERWRITE_UID,
	payload: {
		client: 'v1',
		url: getEndpointUrl('overwrite_uid'),
		method: 'PUT',
		data,
	},
});

export const overwriteRedirectUrl =
	({ redirect, redirectUrl, variant, actiecode }) =>
	(dispatch) => {
		if (!redirect && !redirectUrl && !variant) return;
		let redirectPath;

		if (variant) {
			redirectPath = getRateplanUrl(variant, actiecode);
		} else {
			redirectPath = decodeURIComponent(redirect || redirectUrl);
		}

		try {
			return dispatch({
				type: OVERWRITE_REDIRECT_URL,
				payload: {
					redirectUrl: redirectPath,
				},
			});
		} catch (err) {
			reportError(err);
			throw err;
		}
	};

export const clearRedirectUrl = () => (dispatch) =>
	dispatch({
		type: CLEAR_REDIRECT_URL,
	});

export const videolandLoginRequest = (data) => (dispatch) =>
	dispatch({
		type: VIDEOLAND_LOGIN,
		payload: {
			client: 'accountService',
			url: getEndpointUrl('login_hash'),
			method: 'POST',
			data,
		},
	});

export const accountResetPassword = (email) => (dispatch) =>
	dispatch(resetPassword(email))
		.catch(reportError)
		.then(() => {
			history.push(ROUTE_FORGOT_PASSWORD_SENT);
			dispatch(
				analyticsPush({
					event: EVT_REQ_RESET_LINK,
				})
			);
		});

const resetPassword = (email) => (dispatch) => {
	const returnUrl = encodeURIComponent(`${__HOST__}${ROUTE_LOGIN_EMAIL}`);
	const resetEmail = encodeURIComponent(email);
	const url = `${__HOST__}${ROUTE_FORGOT_PASSWORD_NEW}?returnUrl=${returnUrl}#email=${resetEmail}`;
	return dispatch({
		type: ACCOUNT_RESET_PASSWORD,
		payload: {
			client: 'v5',
			url: getEndpointUrl('password_reset'),
			method: 'POST',
			data: { url, email },
			email,
		},
	});
};

export const checkResetToken = (token) => (dispatch) =>
	dispatch({
		type: ACCOUNT_CHECK_RESET_TOKEN,
		payload: {
			client: 'v5',
			url: getEndpointUrl('check_reset_token', { token }),
			method: 'GET',
		},
	}).catch((err) => {
		reportError(err);
		throw err;
	});

export const updatePassword =
	({ token, newPassword }) =>
	(dispatch) =>
		dispatch({
			type: ACCOUNT_UPDATE_PASSWORD,
			payload: {
				client: 'v5',
				url: getEndpointUrl('update_password', { token }),
				method: 'POST',
				data: { token, newPassword },
			},
		}).catch((err) => {
			reportError(err);
			throw err;
		});

const gigyaLogin = ({ email, password, regToken }) => ({
	type: GIGYA_LOGIN,
	payload: {
		client: 'gigya',
		data: {
			api: 'accounts',
			method: 'login',
			params: {
				loginID: email,
				password,
				regToken,
			},
		},
		email,
	},
});

const gigyaLogout = () => (dispatch) =>
	dispatch({
		type: GIGYA_LOGOUT,
		payload: {
			client: 'gigya',
			data: {
				api: 'accounts',
				method: 'logout',
			},
		},
	});

const identityServiceLogout = (vlHash) => (dispatch) =>
	dispatch({
		type: IDENTITY_SERVICE_LOGOUT,
		payload: {
			method: 'POST',
			client: 'v1',
			url: getEndpointUrl('logoff', { token: vlHash }),
			headers: {
				'videoland-customer-auth-token': vlHash,
			},
			credentials: 'omit',
		},
	});

const gigyaSocialLogin = (provider) => ({
	type: GIGYA_LOGIN,
	meta: { pending: { delay: false } }, // Always trigger _PENDING action
	payload: {
		client: 'gigya',
		data: {
			api: 'accounts',
			method: 'socialLogin',
			params: { provider },
		},
		loginProvider: provider,
	},
});

export const gigyaClosePopup = () => ({
	type: GIGYA_CLOSE_POPUP,
});

/**
 * Linking a social account to an existing account requires calling the 'login' method with
 * loginMode 'link', but this login method does not actually log you in. An explicit login
 * is required afterwards.
 *
 * https://knowledge.gigya.com/GKB_Article?id=a6H400000004DEOEA2&source=a1Y40000006zBDREA2
 */
export const linkAccount = (authData) => (dispatch) =>
	dispatch(gigyaLinkAccount(authData))
		.then(() => dispatch(login(authData)))
		.catch((err) => {
			reportError(err);
			throw err;
		});

const gigyaLinkAccount = ({ email, password, regToken, provider }) => ({
	type: GIGYA_LINK_ACCOUNT,
	payload: {
		client: 'gigya',
		data: {
			api: 'accounts',
			method: 'login',
			params: {
				loginID: email,
				password,
				regToken,
				loginMode: 'link',
			},
		},
		loginProvider: provider,
	},
});

export const deferLogin = (data) => ({
	type: DEFER_LOGIN,
	payload: data,
});

export const resetDeferLogin = () => ({
	type: DEFER_LOGIN_RESET,
});

export const gigyaVerifyLogin = () => (dispatch) =>
	dispatch({
		type: GIGYA_VERIFY_LOGIN,
		payload: {
			client: 'gigya',
			data: {
				api: 'accounts',
				method: 'verifyLogin',
			},
		},
	});

export const gigyaGetAccountInfo = () => (dispatch) =>
	dispatch({
		type: GIGYA_GET_ACCOUNT_INFO,
		payload: {
			client: 'gigya',
			data: {
				api: 'accounts',
				method: 'getAccountInfo',
			},
		},
	});

export const accountClear = () => (dispatch) => {
	dispatch({
		type: ACCOUNT_CLEAR,
	});
};
