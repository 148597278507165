import { getEndpointUrl, getExternalUrl } from '../config';
import {
	ROUTE_HOME,
	ROUTE_TIER_OPTIONS_WINBACK,
	ROUTE_TIER_OPTIONS,
	ROUTE_ACTIVATE_PLAN,
	ROUTE_PARTNER_ACTIVATE,
	ROUTE_PARTNER,
	ROUTE_ACTIVATE_PLAN_STATUS,
	ROUTE_LOGOUT,
	ROUTE_ERROR,
	ROUTE_NOT_FOUND,
	ROUTE_ACTIVATE_VOUCHER,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED,
	ROUTE_MESSAGES_ERROR_BLOCKED_OPEN_PAYMENT,
	ROUTE_MESSAGES_RESUME_PENDING_PAYMENT,
} from '../constants/Routes';
import { ALWAYS_AVAILABLE_ROUTES, AVAILABLE_ROUTES_WITH_BAD_DEBT } from '../constants/Navigation';
import history from '../../common/history';
import { isAbsolute } from './url';
import { TIER_RATEPLANS_DEFAULT, TIER_RATEPLAN_SLUGS } from '../constants/Tiers';
import _isString from 'lodash/isString';
import accountService from '../api/accountService';
import { reportError } from './realUserMonitoring';
import queryUserAccountPropsAuthenticated from '../graphql/queryUserAccountPropsAuthenticated.graphql';
import queryUserIsInDebt from '../graphql/queryUserIsInDebt.graphql';
import { hasActiveSubscription } from './subscription';
import { matchPath, generatePath } from 'react-router';
import { getPaymentPlan } from './payment';
import { DEBT_STATUS } from '../constants/Payment';
import { getRateplanUrl } from './url';
import { isRouteAccessible } from './routes';

export const redirectIfNoActiveSubscription = async ({ subscriptions, router, redirectUrl }) => {
	if (hasActiveSubscription(subscriptions)) return;

	await redirectIfUserHasBadDebtOrWinback(router, redirectUrl);
};

const redirectIfUserHasBadDebtOrWinback = async (router, redirectUrl) => {
	if (__CLIENT__) {
		await accountService(
			{
				url: getEndpointUrl('graphql_path'),
				query: queryUserIsInDebt,
			},
			({ data }) => {
				const isRouteWhitelistedForUserWithBadDebt = !!isRouteAccessibleWithBadDebt(router);
				if (
					data.me.isInDebt === DEBT_STATUS.BAD_DEBT_ON_THIS_ACCOUNT &&
					!isRouteWhitelistedForUserWithBadDebt
				) {
					history.push(ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED);
				} else if (
					data.me.isInDebt === DEBT_STATUS.BAD_DEBT_ON_OTHER_ACCOUNT &&
					!isRouteWhitelistedForUserWithBadDebt
				) {
					history.push(ROUTE_MESSAGES_ERROR_BLOCKED_OPEN_PAYMENT);
				} else {
					const isRouteWhitelistedForNewUser = isRouteAccessible(
						router.location.pathname,
						false,
						true
					);
					if (!isRouteWhitelistedForNewUser) {
						redirectNoSubscription(router, redirectUrl);
					}
				}
			},
			(e) => reportError(e)
		);
	}
};

export const checkRouteWinbackAndRedirect = (router) => {
	checkRouteWinbackWhitelisted(router)
		.then((isRouteWhitelistedForWinbackUser) => {
			if (!isRouteWhitelistedForWinbackUser) {
				history.push(ROUTE_TIER_OPTIONS_WINBACK);
			}
		})
		.catch((e) => {
			console.error(e);
			history.push(ROUTE_TIER_OPTIONS_WINBACK);
		});
};

async function checkRouteWinbackWhitelisted(router) {
	const isActivationPage = isRouteAccessibleWithoutSubscription(router);
	if (isActivationPage) {
		try {
			const variant = await getPaymentPlan(isActivationPage.params.plan);
			return !!variant.isAllowedForWinbackUser;
		} catch (errMessage) {
			return false;
		}
	} else {
		return false;
	}
}

const redirectNoSubscription = async (router, redirectUrl) => {
	if (__CLIENT__) {
		await accountService(
			{
				url: getEndpointUrl('graphql_path'),
				query: queryUserAccountPropsAuthenticated,
				skip: router.location.pathname === ROUTE_LOGOUT,
			},
			({ data }) => {
				if (data.me.isPromo) {
					if (isRouteAccessible(redirectUrl)) {
						history.push(redirectUrl);
					} else {
						history.push(ROUTE_TIER_OPTIONS);
					}
				} else {
					isRouteAccessible(redirectUrl)
						? history.push(redirectUrl)
						: checkRouteWinbackAndRedirect(router);
				}
			},
			(e) => reportError(e)
		);
	}
};

export const redirectToWinbackPage = () => {
	if (__CLIENT__) {
		window.location.href = getExternalUrl('winback');
	}
};

export const redirectHasSubscription = () => {
	if (__CLIENT__) {
		history.push({
			pathname: ROUTE_HOME,
		});
	}
};

export const redirectToRateplan = (rateplan) => {
	if (__CLIENT__) {
		history.push(getRateplanUrl(rateplan));
	}
};

const containsPath = (pathname, redirectPaths) => {
	const containsRedirectPath = new RegExp(`(${redirectPaths.join('|')})`, 'g');
	return !!pathname.match(containsRedirectPath);
};

export const getTierRateplanFromUrl = (redirectUrl) =>
	_isString(redirectUrl)
		? Object.values(TIER_RATEPLANS_DEFAULT).find(
				(rateplanSlug) => redirectUrl.indexOf(rateplanSlug) > -1
		  ) ||
		  (redirectUrl.indexOf('tmobile-standalone-legacy') > -1
				? TIER_RATEPLANS_DEFAULT['PREMIUM']
				: redirectUrl.indexOf('tmobile') > -1
				? TIER_RATEPLANS_DEFAULT['STANDARD']
				: undefined)
		: null;

const actieCodeMatcher = /\?actiecode=/;

export const isVoucherCodeInRedirectUrl = (redirectUrl) => {
	return _isString(redirectUrl) ? !!redirectUrl.match(actieCodeMatcher) : false;
};

export const redirectInternal = (redirectUrl) => {
	redirectUrl = decodeURIComponent(redirectUrl);
	if (isAbsolute(redirectUrl)) {
		const url = new URL(redirectUrl);
		// Check if redirect url is on our old onboarding flows
		// This should be removed once we phase those flows out
		const internalPathPrefixes = ['/onboarding/', '/meldingen/', '/nl/', '/partner/'];
		if (containsPath(url.pathname, internalPathPrefixes)) {
			window.location.href = redirectUrl;
		} else {
			history.push({ pathname: url.pathname, search: url.search });
		}
	} else {
		history.push(redirectUrl);
	}
};

function isRouteAccessibleWithBadDebt(router) {
	return matchPath(router.location.pathname, {
		path: [...AVAILABLE_ROUTES_WITH_BAD_DEBT],
	});
}

function isRouteAccessibleWithoutSubscription(router) {
	return matchPath(router.location.pathname, {
		path: [
			ROUTE_ACTIVATE_VOUCHER,
			ROUTE_PARTNER,
			ROUTE_ACTIVATE_PLAN,
			ROUTE_PARTNER_ACTIVATE,
			ROUTE_ACTIVATE_PLAN_STATUS,
			ROUTE_LOGOUT,
			ROUTE_NOT_FOUND,
			ROUTE_ERROR,
			ROUTE_MESSAGES_RESUME_PENDING_PAYMENT,
			...ALWAYS_AVAILABLE_ROUTES,
		],
	});
}

export const redirectToPromoPlan = (pathname) => {
	const foundPlan = Object.values(TIER_RATEPLAN_SLUGS).find(
		(plan) =>
			!plan.discounted && generatePath(ROUTE_ACTIVATE_PLAN, { plan: plan.evergreen }) === pathname
	);

	if (foundPlan) {
		history.push(generatePath(ROUTE_ACTIVATE_PLAN, { plan: foundPlan.promo }));
	}
};

export const redirectToEvergreenPlan = (pathname) => {
	const foundPlan = Object.values(TIER_RATEPLAN_SLUGS).find(
		(plan) =>
			!plan.discounted && generatePath(ROUTE_ACTIVATE_PLAN, { plan: plan.promo }) === pathname
	);

	if (foundPlan) {
		history.push(generatePath(ROUTE_ACTIVATE_PLAN, { plan: foundPlan.evergreen }));
	}
};
