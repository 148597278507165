import React from 'react';
import { useSelector } from 'react-redux';
import { Usabilla } from './usabilla/Usabilla';
import { hasSubscription } from '../../helpers/state';
import { showConsentPopup } from '../../../client/onetrust';
import {
	FOOTER_COMPANY_DETAILS,
	FOOTER_CONTACT,
	FOOTER_COOKIE,
	FOOTER_PRIVACY,
	FOOTER_REQ,
} from '../../constants/Copy';
import './Footer.scss';
import { ROUTE_COMPANY_INFORMATION } from '../../constants/Routes';
import { Link } from 'react-router-dom';
import { PRIVACY_URL, CONDITIONS_URL, HELP_URL } from '../../config';

function Footer({ hideFooter, fullWidthFooter }) {
	const activeSubscription = useSelector((state) => hasSubscription(state));

	return (
		<footer className={`footer ${fullWidthFooter ? 'footer--fullwidth' : ''}`}>
			{!activeSubscription || hideFooter ? null : (
				<div className="footer-usabilla">
					<Usabilla />
				</div>
			)}
			<div className="footer-nav" data-test="footer-nav">
				<ul className="footer-nav__list">
					<li className="footer-nav__item">&copy; {new Date().getFullYear()} Videoland</li>
					<li className="footer-nav__item">
						<Link
							to={ROUTE_COMPANY_INFORMATION}
							className="footer-nav__link"
							data-test="footer-company-info"
						>
							{FOOTER_COMPANY_DETAILS}
						</Link>
					</li>
					<li className="footer-nav__item">
						<a
							href={`${HELP_URL}/article/hoe-kan-ik-contact-opnemen`}
							className="footer-nav__link"
							target="_blank"
							rel="noopener"
						>
							{FOOTER_CONTACT}
						</a>
					</li>
					<li className="footer-nav__item">
						<a href={CONDITIONS_URL} className="footer-nav__link" target="_blank" rel="noopener">
							{FOOTER_REQ}
						</a>
					</li>
					<li className="footer-nav__item">
						<a href={PRIVACY_URL} className="footer-nav__link" target="_blank" rel="noopener">
							{FOOTER_PRIVACY}
						</a>
					</li>
					<li className="footer-nav__item">
						<button
							onClick={() => showConsentPopup()}
							className="footer-nav__link"
							target="_blank"
							rel="noopener"
						>
							{FOOTER_COOKIE}
						</button>
					</li>
				</ul>
			</div>
		</footer>
	);
}

export default Footer;
