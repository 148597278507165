import React, { useEffect } from 'react';
import routes from '../../routes';
import { AnalyticsStarter } from '../../components/analytics/AnalyticsStarter';
import { useRedirectIfTransactionInProgress } from '../../hooks/useRedirectIfTransactionInProgress';
import { useRedirectUser } from '../../hooks/useRedirectUser';
import { useOnboardingGeoBlock } from '../../hooks/useOnboardingGeoBlock';
import UsabillaStarter from '../analytics/UsabillaStarter';
import { useHandleRedirectReason } from '../../hooks/useHandleRedirectReason';
import { useGigya } from '../../hooks/useGigya';
import { stateRender } from '../../helpers/react';
import { runOtlp } from '@rtl_nl/rtl-otel-js-web';
import { matchPath, useLocation } from 'react-router-dom';
import { getConfig } from '../../apiConfig';
import { useSubscription } from '../../hooks/useSubscription';
import { ROUTE_TIER_OPTIONS } from '../../constants/Routes';
import { useSubscriptionTier } from '../../hooks/useSubscriptionTier';
import { hasValidVideolandCookie } from '../../helpers/authentication';

export function App() {
	const location = useLocation();
	const { opentelemetry_url } = getConfig();

	useEffect(() => {
		runOtlp({
			serviceName: 'videoland-web',
			version: __BUILDNUMBER__ ?? 'dev',
			browserRoute: location.pathname,
			otlp: {
				url: opentelemetry_url,
			},
		});
	}, [location.pathname, opentelemetry_url]);

	const isLoggedIn = hasValidVideolandCookie();
	const allowPending = !matchPath(location.pathname, ROUTE_TIER_OPTIONS);
	const { success: isGeoBlockedReady } = useOnboardingGeoBlock();
	const { isReady: isGigyaReady } = useGigya();
	const { loading: isRedirectIfTransLoading } = useRedirectIfTransactionInProgress();
	const { loading: isSubscriptionLoading, error: subscriptionHasError } = useSubscription();
	const { loading: isSubscriptionTierLoading } = useSubscriptionTier();
	const isAppPending =
		!isGeoBlockedReady ||
		!isGigyaReady ||
		(isLoggedIn && (isSubscriptionLoading || isSubscriptionTierLoading));

	useRedirectUser({ skip: isRedirectIfTransLoading });
	useHandleRedirectReason({ skip: isSubscriptionLoading });

	return stateRender({
		isPending: allowPending && isAppPending,
		isError: subscriptionHasError,
		spinnerModifierClass: 'spinner--large centered',
		onSuccess: () => (
			<>
				<UsabillaStarter />
				<AnalyticsStarter>{routes}</AnalyticsStarter>
			</>
		),
	});
}
