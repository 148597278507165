import { generatePath } from 'react-router';
import { TIER_RATEPLAN_SLUGS, TIERS_SORT_ORDER, TIER_IDS, TIER_FREE } from '../constants/Tiers';
import { ROUTE_REGISTER, ROUTE_TIER_OPTIONS, ROUTE_ACTIVATE_PLAN } from '../constants/Routes';
import { TierTitles } from '../constants/TierTitles';
import { TierFeatures } from '../constants/TierFeatures';
import { TierPresentations } from '../constants/TierPresentations';
import { PARAM_NAMES } from '../constants/Checkout';

const routeTierOptionsMatcher = new RegExp(`^${ROUTE_TIER_OPTIONS}/?$`);

export const getTierFromCollection = (tierCollection, tierId) => {
	return tierId === TIER_IDS.FREE ? TIER_FREE : tierCollection.find(({ id }) => id === tierId);
};

export const getPromoUrlForTier = ({ id }) => {
	const planSlug = TIER_RATEPLAN_SLUGS[id].promo;
	return getActivationUrl(planSlug);
};

export const getNonPromoUrlForTier = ({ id }) => {
	const planSlug = TIER_RATEPLAN_SLUGS[id].evergreen;
	return getActivationUrl(planSlug);
};

function getActivationUrl(planSlug) {
	return generatePath(ROUTE_ACTIVATE_PLAN, {
		plan: planSlug,
	});
}

export const getNonPromoPriceForTier = ({ pricing }) => {
	return pricing?.recurring;
};

export const getPromoPriceForTier = ({ promoPricing }) => {
	return promoPricing?.recurring;
};

export const getTitleForTier = ({ id }) => TierTitles[id];

export const getTierFeature = ({ feature_id }) => TierFeatures[feature_id];

export const getPresentationForTier = (id) => TierPresentations[id];

export const getTierPosition = (tierId, tierCollection) => {
	const sortedTiers = sortTiers(tierCollection);
	const foundIndex = sortedTiers.findIndex((tierItem) => tierItem.id === tierId);
	return foundIndex > -1 ? foundIndex + 1 : -1;
};

export const isPromoTierOptionsPage = (pathname) => !!pathname.match(routeTierOptionsMatcher);

export const getTierOptionsProceedUrl = (selectedTier, isLoggedIn, isPromoPresentation, referral) => {
	if (selectedTier) {
		const getPathAndQuery = () => {
			if (!isLoggedIn && isPromoPresentation) {
				return ROUTE_REGISTER + `/?${PARAM_NAMES.REDIRECT_URL}=${getPromoUrlForTier(selectedTier)}`;
			} else {
				if (isPromoPresentation) {
					return getPromoUrlForTier(selectedTier);
				} else {
					return getNonPromoUrlForTier(selectedTier);
				}
			}
		};

		let pathAndQuery = getPathAndQuery();

		if (referral){
			pathAndQuery += pathAndQuery.includes("?") ? "&" : "?";
			pathAndQuery += `${PARAM_NAMES.REFERRAL}=${referral}`;
		}

		return pathAndQuery;
	}
	return null;
};

export const sortTiers = (tierCollection) => {
	const tiersSortOrderObject = TIERS_SORT_ORDER.reduce(
		(acc, val, index) => ({ ...acc, [val]: index }),
		{}
	);
	const unknownToEndIndex = TIERS_SORT_ORDER.length + 1;
	return [...tierCollection].sort((tierA, tierB) => {
		let tierSortIndexA = tiersSortOrderObject[tierA.id] ?? unknownToEndIndex;
		let tierSortIndexB = tiersSortOrderObject[tierB.id] ?? unknownToEndIndex;
		return tierSortIndexA - tierSortIndexB;
	});
};

export const getTierInfo = (tier, tierCollection) => {
	const tierIndex = tierCollection.findIndex((t) => t.id === tier);
	if (tierIndex === -1) return {};

	const tierItem = tierCollection[tierIndex];
	const { title } = getPresentationForTier(tierItem.id);
	return { pos: tierIndex + 1, price: tierItem.pricing.recurring, title };
};

export function isOnFreeTier(tier) {
	return tier === TIER_IDS.FREE;
}
