const HELP_URL = 'https://hulp.videoland.com';
const CONDITIONS_URL = `${HELP_URL}/article/gebruikersvoorwaarden-videoland`;
const PRIVACY_URL = `${HELP_URL}/article/privacy-en-cookiestatement-videoland`;
const VOUCHER_URL = `https://www.videoland.com/activeer/voucher`;
const ABOUT_BASIS = `${HELP_URL}/article/wat-kan-ik-met-het-basis-abonnement`;
const ABOUT_PLUS = `${HELP_URL}/article/wat-kan-ik-met-het-plus-abonnement`;
const ABOUT_PREMIUM = `${HELP_URL}/article/wat-kan-ik-met-het-premium-abonnement`;

export const ONBOARDING_PRIVACY_TITLE = 'Ga je akkoord met onze voorwaarden?';
export const ONBOARDING_PRIVACY_DESCRIPTION =
	'Als je doorgaat, ga je akkoord met het {privacy} en geef je ons toestemming om met behulp van cookies of andere technieken jouw persoonsgegevens te verzamelen.';
export const ONBOARDING_PRIVACY_INVALID = 'Ga je akkoord met onze voorwaarden?';
export const ONBOARDING_PRIVACY_CTA = 'Ja, ik ga akkoord';
export const ONBOARDING_TERMS_USER_CONDITIONS = 'gebruikersvoorwaarden';
export const ONBOARDING_PRIVACY_NAME = 'RTL Privacy en Cookiestatement';
export const ONBOARDING_PRIVACY_URL = 'https://privacy.rtl.nl/privacy-statement';
export const ONBOARDING_TERMS_OF_USE_NAME = 'gebruiksvoorwaarden';
export const ONBOARDING_TERMS_TEXT_TITLE1 = 'Waarom verzamelen we jouw gegevens?';
export const ONBOARDING_TERMS_TEXT_SECTION1 = `We verzamelen gegevens over jouw gebruik van alle websites en apps van RTL. Deze informatie, zoals de video's die je bekijkt of je locatie, wordt onder andere gebruikt voor het optimaliseren van onze diensten en het aanbieden van advertenties en aanbiedingen zo relevant mogelijk voor je te maken op basis van bijvoorbeeld je locatie of gebruikte apparaat.`;
export const ONBOARDING_TERMS_TEXT_SECTION2 = `Ook stem je in met de {termsOfUse} van Videoland. Deze zijn geldig indien je besluit een dienst van Videoland af te nemen.`;
export const ONBOARDING_TERMS_FULL_TEXT = 'Klik hier voor de volledige tekst';
export const ONBOARDING_TERMS_CONNECT_PARTNER =
	'Ook ga je akkoord met het feit dat je klant wordt bij Videoland. Je betaalt het Videoland abonnement via je tv-aanbieder';

export const TERMS_AND_CONDITIONS_CTA = 'Ja, ik ga akkoord met de actievoorwaarden';
export const TERMS_AND_CONDITIONS_SUBTITLE = 'Omschrijving van de actie';

export const NPL_WINBACK_TERMS = {
	title: 'Algemene Actievoorwaarden Nationale Postcode Loterij X Videoland aanbod – 2022',
	description:
		'Op de tijdelijke Nationale Postcode Loterij X Videoland aanbod – 2022 zijn de volgende voorwaarden van toepassing:',
	text: [
		'Dit persoonlijke aanbod is alleen beschikbaar voor Gebruikers die reeds via de Nationale Postcode Loterij tijdelijk (6 maanden) over een Videoland Plus abonnement beschikken welk abonnement is afgelopen tussen 1 juli 2022 en 1 september 2022.',
		'Inhoud van het aanbod:  6 maanden een Videoland Basis abonnement voor een tijdelijke actieprijs van € 2,99 per maand i.p.v. het huidige reguliere tarief van € 4,99.',
		'Het aanbod is enkel van toepassing op het Videoland Basis abonnement en geldt dus niet voor andere Videoland abonnement soorten.',
		'Het aanbod geldt vanaf het moment dat je na afloop van jouw tijdelijke Videoland Plus abonnement een persoonlijke actiecode ontvangt per e-mail van Videoland tot en met 31 december 2022 (Actieperiode).',
		'Om gebruik te maken van dit persoonlijke aanbod dien je jouw persoonlijke actiecode binnen de Actieperiode te activeren via de pagina: videoland.com/code.',
		'Je kunt je abonnement tussentijds opzeggen (zie voor meer informatie de Gebruikersvoorwaarden van Videoland). Dat betekent dat je ook korter dan 6 maanden gebruik maken van dit tijdelijke voordelige actietarief. Sluit je daarna opnieuw een Videoland abonnement af, dan geldt dit aanbod niet langer en maak je gebruik van de dan geldende tarieven.',
		'Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>mét reclame</strong>. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot download to go faciliteit. Meer informatie over het Videoland Basis abonnement vind je op <a href="https://www.videoland.com/nl/" target="_blank" rel=“nofollow”>www.videoland.com</a>. Met Videoland Basis kun je niet kijken op een TV-ontvanger (Ziggo Horizon/Next, KPN, XS4all, Budget). Je kunt wel gewoon kijken via je laptop, desktop, mobiele Videoland app op je telefoon of tablet (iOS en Android), Smart tv en PS4.',
		'Op dit aanbod is geen gratis proefperiode van veertien (14) dagen van toepassing. Dit betekent dat de Gebruiker de tijdelijke maandelijkse actieprijs direct verschuldigd is aan Videoland vanaf de datum waarop Videoland de aanmelding van de Gebruiker bevestigt en begint.',
		'Na 6 maanden loopt je Videoland Basis abonnement automatisch door en betaal je het op dat moment geldende tarief van Videoland Basis. De exacte start datum vind je terug in je Videoland Account.',
		`Op het Videoland Basis abonnement dat op grond van dit aanbod wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel=“nofollow”>Gebruikersvoorwaarden</a> van Videoland en het  <a href=${PRIVACY_URL} target="_blank" rel=“nofollow”>Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende afspraken zijn gemaakt.`,
		`Heb je vragen over het aanbod, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel=“nofollow”>klantenservice</a>. De Klantenservice is bereikbaar middels het contactformulier en/of de chatfunctie op de website www.videoland.com, telefonisch via 088 - 123 24 56 en/of via de social media-kanalen van Videoland.`,
		'Wijzigingen voorbehouden.',
	],
};

export const LTC_12_MAANDEN = {
	title: 'Videoland Plus jaarabonnement ',
	description:
		'Op de Videoland Plus jaarabonnement actie zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'Het actie aanbod betreft het Videoland Plus jaarabonnement (12 maanden) voor de prijs van 10 maanden, te weten 99,90 EURO inclusief btw i.p.v. de reguliere prijs voor 12 maanden van 107,88 EURO inclusief btw.',
		'De gebruiker betaalt dit bedrag in één keer vooraf tijdens het afsluiten van het abonnement. ',
		'Na 12 maanden loopt het abonnement automatisch door en betaalt de gebruiker het dan geldende maandtarief van het Videoland Plus abonnement welke maandelijks wordt afgerekend. Het abonnement is vanaf dan maandelijks opzegbaar (zie voor meer informatie de algemene voorwaarden).',
		'Als de gebruiker na verloop van de eerste 12 maanden van het jaarabonnement het Videoland Plus abonnement wil stopzetten, dan kan dit tot uiterlijk één dag voor het verlopen van het Videoland Plus jaarabonnement. De exacte datum hiervan vind je terug in je Videoland Account.',
		'De actie is enkel van toepassing op het Videoland Plus abonnement en geldt dus niet voor de andere Videoland abonnement soorten.',
		'Het aanbod kan alleen worden afgesloten in de periode 17 september t/m 20 november 2022 (de actieperiode) door middel van activatie binnen deze actieperiode via de actiepagina <a href="https://www.videoland.com/nl/titels/glory/" target="_blank" rel=“nofollow”>videoland.com/glory</a>',
		'Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en documentaires van Videoland kunt kijken zonder reclame. Je kunt 2 streams tegelijk afspelen en gebruik maken van de download to go faciliteit. ',
		'Op deze actie is de gratis proefperiode van veertien (14) dagen niet van toepassing. Ook is dit abonnement niet maandelijks tussentijds op te zeggen. Indien de gebruiker tijdens het jaar opzegt, wordt het Videoland Plus jaarabonnement aan het einde van de periode van 12 maanden beëindigd. ',
		'Het actie aanbod geldt alleen voor nieuwe Videoland klanten. Bestaande klanten kunnen hier geen gebruik van maken. ',
		`Op het Videoland Plus jaarabonnement dat op grond van de actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel=“nofollow”>Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel=“nofollow”>Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende afspraken zijn gemaakt.`,
		`Heb je vragen over de actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel=“nofollow”>klantenservice</a>.`,
		'Wijzigingen voorbehouden. ',
	],
};

export const BLACK_FRIDAY_TERMS = {
	title: 'Actievoorwaarden Black Friday Deal 2022',
	description:
		'Op de Black Friday Deal 2022 (hierna: de BFD Deal) zijn de volgende voorwaarden van toepassing:',
	text: [
		'Je kunt deze deal alleen afnemen in de periode  vanaf 21 t/m 28 november 2022 (actieperiode), via de website videoland.com.',
		'Inhoud van de BFD Deal:  6 maanden lang gebruik van het Videoland Basis abonnement voor de tijdelijke actieprijs van EURO 2,99 per maand i.p.v. het huidige tarief van EURO 4,99. De BFD Deal is enkel van toepassing op het Videoland Basis abonnement en geldt dus niet voor de andere Videoland abonnement soorten.',
		'Je kunt je abonnement tussentijds opzeggen (zie voor meer informatie de algemene voorwaarden). Dat betekent dat je ook korter dan 6 maanden gebruik kunt maken van het voordelige BFD Deal tarief. Sluit je daarna opnieuw een Videoland abonnement af, dan geldt deze BFD Deal niet langer en maak je gebruik van de dan geldende tarieven.',
		`Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>mét reclame</strong>. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot de download to go faciliteit. Meer info over het Videoland Basis abonnement vind je <a href="${ABOUT_BASIS}" target="_blank" rel="noopener">hier</a>.`,
		'De aanbieding is enkel van toepassing op het Videoland Basis abonnement en geldt dus niet voor de andere Videoland abonnement soorten.',
		'Na 6 maanden loopt je Videoland basis abonnement automatisch door en betaal je het op dat moment geldende tarief voor het Videoland Basis abonnement. De exacte start datum vind je terug in je Videoland Account.',
		'Heb je nog nooit eerder een Videoland abonnement gehad? Dan kijk je de eerste 2 weken gratis.',
		'De deal geldt alleen voor nieuwe Videoland klanten. Bestaande klanten kunnen hier geen gebruik van maken.',
		`Op het Videoland basis abonnement dat op grond van de deal wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende afspraken zijn gemaakt.`,
		`Heb je vragen over de deal, neem dan contact op met de Videoland  <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		'Wijzigingen voorbehouden.',
	],
};

export const NPL_2023 = {
	title: 'Actievoorwaarden Postcode Loterij aanbod 2023',
	description:
		'Op het Postcode Loterij aanbod 2023 (hierna: PL aanbod) zijn de volgende voorwaarden van toepassing:',
	text: [
		'Je kunt dit aanbod afnemen vanaf het moment dat de e-mail met het aanbod is verstuurd ontvangen t/m uiterlijk 30 dagen daarna (actieperiode), via de website videoland.com. De totale actie eindigt op 31 december 2023.',
		'Inhoud van het PL aanbod: 12 maanden lang gebruik van het Videoland Basis abonnement voor de tijdelijke actieprijs van EURO 2,99 per maand i.p.v. het huidige tarief van EURO 4,99 óf 12 maanden lang gebruik van het Videoland Plus abonnement voor de tijdelijke actieprijs van EURO 6,99 per maand i.p.v. het huidige tarief van EURO 9,99.',
		'Het aanbod is enkel van toepassing op het Videoland Basis of Plus abonnement en geldt dus niet voor de andere Videoland abonnement soorten.',
		'Je kunt je abonnement tussentijds opzeggen (zie voor meer informatie de algemene voorwaarden). Dat betekent dat je ook korter dan 12 maanden gebruik kunt maken van het voordelige PL aanbod tarief. Sluit je daarna opnieuw een Videoland abonnement af, dan geldt deze aanbieding niet langer en maak je gebruik van de dan geldende tarieven.',
		`Het Videoland Basis abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>mét reclame</strong>. Je kunt 1 stream tegelijk afspelen en hebt geen toegang tot de Download to Go faciliteit. Meer info over het Videoland Basis abonnement vind je <a href="${ABOUT_BASIS}" target="_blank" rel="noopener">hier</a>.`,
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		'Na 12 maanden loopt je Videoland Basis of Plus abonnement automatisch door en betaal je het op dat moment geldende tarief voor het abonnement dat je hebt gekozen. De exacte start datum vind je terug in je Videoland Account.',
		'Op dit aanbod is de gratis proefperiode van veertien (14) dagen niet van toepassing. Dit betekent dat de gebruiker de tijdelijke maandelijkse actieprijs direct verschuldigd is aan Videoland vanaf de datum waarop Videoland de aanmelding van de gebruiker bevestigt en begint.',
		'Dit aanbod geldt alleen voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		`Op het Videoland Basis en Plus abonnement dat op grond van dit aanbod wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het  <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende afspraken zijn gemaakt.`,
		`Heb je vragen over dit aanbod, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		'Wijzigingen voorbehouden.',
	],
};

const ANNUAL_SUBSCRIPTION_PROMOTION_2023 = {
	title: 'Voorwaarden jaarabonnement Videoland base 2023',
	description:
		'Op de jaarabonnement actie 2023 (hierna: de "Actie”) zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'De looptijd van de Actie is vanaf 16 mei 2023 tot en met 19 juni 2023.',
		'Via de Actie kun je een jaarabonnement afsluiten voor Videoland Plus voor 8,99 per maand i.p.v. het nieuwe tarief van 9,99 per maand of Videoland Premium voor 10,99 per maand i.p.v. het nieuwe tarief van 11,99 per maand.',
		'Bij het activeren van het jaarabonnement betaal je de eerste maand vooruit. De incassodatum van je huidige abonnement wordt na het aflopen van het jaarabonnement weer hervat.',
		'De gebruiker gaat bij het afsluiten van dit abonnement een commitment aan voor 12 maanden. Dit abonnement is niet tussentijds opzegbaar.',
		'De Actie kan niet worden gecombineerd met andere acties. De actie is niet beschikbaar voor gebruikers die reeds gebruik maken van een actie d.m.v. een vouchercode. De eventuele proefperiode waarvan de gebruiker op het moment van het aangaan van het jaarabonnement gebruik maakt komt per direct te vervallen.',
		'Na 12 maanden loopt het abonnement automatisch door en betaal je vanaf dat moment het tarief dat geldt voor het abonnement dat je hebt gekozen. De exacte startdatum en prijs vind je terug in je Videoland Account.',
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		`Het Videoland Premium abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 4 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Premium abonnement vind je <a href="${ABOUT_PREMIUM}" target="_blank" rel="noopener">hier</a>.`,
		'Je kunt van de Actie gebruik maken vanaf 16 mei 2023 t/m uiterlijk 19 juni. Je kunt hierbij gebruik maken van de vouchercode uit de email en het abonnement afsluiten via <a href="https://www.videoland.com/activeer/voucher" target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>',
		'De Actie is uitsluitend van toepassing op het Videoland Plus of Videoland Premium jaarabonnement en geldt niet voor andere Videoland abonnementen.',
		'De Actie is alleen beschikbaar voor bestaande Videoland abonnees die op 15 mei een Videoland Plus of Premium abonnement hebben. Nieuwe abonnees kunnen hier geen gebruik van maken. Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus en Videoland Premium abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen zijn opgenomen.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a>.`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum van ingang.',
	],
};

const MKT_SUMMER_OFFER = {
	title: 'Actievoorwaarden Zomeractie- 3 maanden plus voor €14,99 2023',
	description:
		'Op de zomeractie - 3 maanden plus voor €14,99 2023 (hierna: de "Actie”) zijn de volgende voorwaarden van toepassing',
	text: [
		'De Actie is alleen af te nemen in de periode van 21 juni 2023 tot en met 21 september 2023 (hierna: de “Actieperiode”) door personen die een persoonlijke vouchercode hebben ontvangen voor de zomeractie 2023 per e-mail.',
		'Met de Actie kun je 3 maanden lang gebruik maken van het Videoland Plus abonnement voor de tijdelijke actieprijs van €14,99 per drie maanden in plaats van het huidige tarief van €29,97 per drie maanden. Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en documentaires van Videoland kunt kijken zonder reclame. Je kunt 2 streams tegelijk afspelen en je kunt gebruik maken van de download to go faciliteit. Meer informatie over het Videoland Plus abonnement vind je hier.',
		'Om gebruik te maken van de Actie dien je jouw persoonlijke actiecode voor de Actie die je per e-mail hebt ontvangen te activeren via: https://www.videoland.com/activeer/voucher.',
		'De Actie is uitsluitend van toepassing op het Videoland Plus abonnement en geldt niet voor andere Videoland abonnement soorten.',
		'Let op: je kunt je Videoland Plus abonnement gedurende de Actieperiode niet tussentijds opzeggen. Na de Actieperiode kun je jouw Videoland Plus abonnement altijd tussentijds opzeggen (zie voor meer informatie de Gebruikersvoorwaarden).',
		'Na 3 maanden loopt je Videoland Plus abonnement automatisch door en betaal je het op dat moment geldende reguliere tarief voor Videoland Plus. De exacte startdatum hiervan vind je terug in je Videoland account.',
		'Op deze Actie is de gratis proefperiode van 14 dagen niet van toepassing. Dit betekent dat je als abonnee de tijdelijke maandelijkse actieprijs direct verschuldigd bent aan Videoland vanaf de datum waarop Videoland jouw aanmelding heeft bevestigt en waarop jouw abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		'Op het Videoland Plus abonnement dat op grond van deze Actie wordt aangegaan zijn de Gebruikersvoorwaarden van Videoland en het Privacy- en cookiestatement van Videoland van toepassing, behalve voor zover in deze actievoorwaarden afwijkende bepalingen gelden.',
		'Heb je vragen over deze Actie? Neem dan contact op met klantenservice van Videoland via één van de volgende servicekanelen: Whatsapp naar 06-1445 92 77, telefonisch via 088-123 2456, de chatfunctie, het contactformulier of de social media kanalen vermeld op www.videoland.com.',
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst.',
	],
};

const MKT_SUMMER_OFFER_50_PERCENT = {
	title: 'Actievoorwaarden Zomeractie 2023',
	description:
		'Op de zomeractie 2023 (hierna: de "Actie”) zijn de volgende voorwaarden van toepassing:',
	text: [
		'De Actie is alleen af te nemen in de periode van 21 juni 2023 tot en met 21 september 2023 (hierna: de “Actieperiode”) door personen die een persoonlijke vouchercode hebben ontvangen voor de zomeractie 2023 per e-mail.',
		`Met de Actie kun je 3 maanden lang gebruik maken van het Videoland Plus abonnement voor de tijdelijke actieprijs van €4,99 per maand in plaats van het huidige tarief van €9,99 per maand. Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en documentaires van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en je kunt gebruik maken van de download to go faciliteit. Meer informatie over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		'Om gebruik te maken van de Actie dien je jouw persoonlijke actiecode voor de Actie die je per e-mail hebt ontvangen te activeren via: <a href="https://www.videoland.com/activeer/voucher" target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>.',
		'De Actie is uitsluitend van toepassing op het Videoland Plus abonnement en geldt niet voor andere Videoland abonnement soorten.',
		'Je kunt je gekozen Videoland abonnement altijd tussentijds opzeggen (zie voor meer informatie de Gebruikersvoorwaarden). Dat betekent dat je ook korter dan 3 maanden gebruik kunt maken van de tijdelijke actieprijs. Sluit je na je opzegging opnieuw een Videoland abonnement af, dan kun je niet langer gebruikmaken van de actieprijs en zijn de dan geldende reguliere tarieven van toepassing.',
		'Na 3 maanden loopt je Videoland Plus abonnement automatisch door en betaal je het op dat moment geldende reguliere tarief voor Videoland Plus. De exacte startdatum hiervan vind je terug in je Videoland account.',
		'Op deze Actie is de gratis proefperiode van 14 dagen niet van toepassing. Dit betekent dat je als abonnee de tijdelijke maandelijkse actieprijs direct verschuldigd bent aan Videoland vanaf de datum waarop Videoland jouw aanmelding heeft bevestigt en waarop jouw abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Bestaande abonnees kunnen hier geen gebruik van maken.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>  van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing, behalve voor zover in deze actievoorwaarden afwijkende bepalingen gelden.`,
		`Heb je vragen over deze Actie? Neem dan contact op met <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice</a> van Videoland via één van de volgende servicekanelen: Whatsapp naar 06-1445 92 77, telefonisch via 088-123 2456, de chatfunctie, het contactformulier of de social media kanalen vermeld op www.videoland.com.`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst.',
	],
};

const FALL_OFFER_CANCELLABLE = {
	title: 'Voorwaarden speciaal aanbod Najaar 2023',
	description:
		'Op de Najaar actie 2023 (hierna: de "Actie”) zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'Via de Actie kun je een 6 maanden Videoland Plus abonnement afsluiten voor € 7,99 per maand i.p.v. € 9,99 per maand.',
		`Je kunt je abonnement tussentijds opzeggen (zie voor meer informatie artikel 3 van de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>). Dat betekent dat je ook korter dan 6 maanden gebruik kunt maken van het voordelige tarief. Sluit je daarna opnieuw een Videoland abonnement af, dan geldt deze actie niet langer en maak je gebruik van de dan geldende tarieven.`,
		'De Actie kan niet worden gecombineerd met andere acties.',
		'Na 6 maanden loopt het abonnement automatisch door en betaal je vanaf dat moment het tarief dat geldt voor het abonnement dat je hebt gekozen. De exacte startdatum en prijs vind je terug in je Videoland Account.',
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		`Je kunt van de Actie gebruik maken vanaf 26 september 2023 t/m uiterlijk 31 oktober 2023. Je kunt hierbij gebruik maken van de vouchercode uit de email en het abonnement afsluiten via <a href=${VOUCHER_URL} target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>`,
		'De Actie is uitsluitend van toepassing op het Videoland Plus abonnement en geldt niet voor andere Videoland abonnementen.',
		'De Actie is alleen beschikbaar voor voormalige Videoland abonnees die hierover een e-mail hebben ontvangen.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen zijn opgenomen.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice.</a>`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum van ingang.',
	],
};

const FALL_OFFER_NON_CANCELLABLE = {
	title: 'Voorwaarden speciaal aanbod Najaar 2023',
	description:
		'Op de Najaar actie 2023 (hierna: de "Actie”) zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'Via de Actie kun je één van de volgende aanbiedingen afsluiten: 6 maanden Videoland Plus abonnement voor € 7,99 per maand i.p.v. € 9,99 per maand of 6 maanden Videoland Premium abonnement voor € 9,99 per maand i.p.v. € 11,99 per maand.',
		'Het abonnement is niet tussentijds opzegbaar.',
		'De Actie kan niet worden gecombineerd met andere acties.',
		'Na 6 maanden loopt het abonnement automatisch door en betaal je vanaf dat moment het tarief dat geldt voor het abonnement dat je hebt gekozen. De exacte startdatum en prijs vind je terug in je Videoland Account.',
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		`Het Videoland Premium abonnement houdt in dat je alle series, films, programma’s en docu’s van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 4 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Premium abonnement vind je <a href="${ABOUT_PREMIUM}" target="_blank" rel="noopener">hier</a>.`,
		`Je kunt van de Actie gebruik maken vanaf 26 september 2023 t/m uiterlijk 31 oktober 2023. Je kunt hierbij gebruik maken van de vouchercode uit de email en het abonnement afsluiten via <a href=${VOUCHER_URL} target="_blank" rel="noopener">https://www.videoland.com/activeer/voucher</a>`,
		'De Actie is uitsluitend van toepassing op het Videoland Plus of Videoland Premium 6 maanden abonnement en geldt niet voor andere Videoland abonnementen.',
		'De Actie is alleen beschikbaar voor voormalige Videoland abonnees die hierover een e-mail hebben ontvangen.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus en Videoland Premium abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen zijn opgenomen.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice.</a>`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst, voorzien van een datum van ingang.',
	],
};

const VRIENDEN_LOTERIJ_6_MAANDEN = {
	title: 'Voorwaarden VriendenLoterij actie 2023',
	description:
		'Op de VriendenLoterij actie (hierna: de "Actie”) zijn de volgende actievoorwaarden van toepassing:',
	text: [
		'De looptijd van de actie is vanaf 12 oktober 2023 tot en met 31 december 2023. Om gebruik te maken van de Actie dien je de link te gebruiken die je per e-mail hebt ontvangen en vóór 31 december 2023 je abonnement te activeren.',
		'De Actie is alleen beschikbaar voor personen die een link hebben ontvangen via de VriendenLoterij.',
		'Met de Actie kan je 6 maanden lang gebruik maken van het Videoland Plus abonnement voor de tijdelijke actieprijs van € 6,99 per maand in plaats van het huidige tarief van € 9,99 per maand.',
		`Het Videoland Plus abonnement houdt in dat je alle series, films, programma’s en documentaires van Videoland kunt kijken <strong>zonder reclame</strong>. Je kunt 2 streams tegelijk afspelen en kunt gebruik maken van de Download to Go faciliteit. Meer info over het Videoland Plus abonnement vind je <a href="${ABOUT_PLUS}" target="_blank" rel="noopener">hier</a>.`,
		`Je kunt je Videoland Plus abonnement tussentijds opzeggen (zie voor meer informatie artikel 3 van de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a>). Dat betekent dat je ook korter dan 6 maanden gebruik kunt maken van de voordelige actieprijs. Sluit je na je opzegging opnieuw een Videoland abonnement af, dan kun je niet langer gebruikmaken van de actieprijs en zijn de dan geldende tarieven van toepassing.`,
		'De Actie kan niet worden gecombineerd met andere acties.',
		'Na 6 maanden loopt het door jouw aangeschafte Videoland Plus abonnement automatisch door en betaal je het op dat moment geldende tarief voor Videoland Plus. De exacte startdatum van jou Videoland Plus abonnement vind je terug in je Videoland account.',
		'De Actie is uitsluitend van toepassing op het Videoland Plus abonnement en geldt niet voor andere Videoland abonnement soorten.',
		'Op deze Actie is de gratis proefperiode van veertien (14) dagen niet van toepassing. Dit betekent dat je als abonnee de tijdelijke maandelijkse actieprijs direct verschuldigd bent aan Videoland vanaf de datum waarop Videoland jouw aanmelding bevestigt en waarop jouw Videoland Plus abonnement ingaat.',
		'De Actie is alleen beschikbaar voor nieuwe Videoland abonnees. Indien je een bestaande Videoland abonnee bent of in de afgelopen 24 maanden voorafgaand aan de Actie gebruik hebt gemaakt van een Videoland abonnement dan kan je geen gebruik maken van de Actie.',
		'Videoland en de deelnemers aan de Actie zijn gebonden aan de inhoud en bepalingen van deze actievoorwaarden.',
		`Op het Videoland Plus abonnement dat op grond van deze Actie wordt aangegaan zijn de <a href=${CONDITIONS_URL} target="_blank" rel="noopener">Gebruikersvoorwaarden</a> van Videoland en het <a href=${PRIVACY_URL} target="_blank" rel="noopener">Privacy- en cookiestatement</a> van Videoland van toepassing behalve voor zover in deze actievoorwaarden afwijkende bepalingen zijn opgenomen.`,
		`Heb je vragen over deze Actie, neem dan contact op met de Videoland <a href=${HELP_URL} target="_blank" rel="noopener">klantenservice.</a>.`,
		'Videoland behoudt zich het recht voor te allen tijde, zonder opgaaf van reden de Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen. Een herziene versie van de actievoorwaarden zal op de website van Videoland worden geplaatst.',
	],
};

export const RATEPLAN_WITH_TERMS = [
	{
		planId: 'Essential6M299', //voucher rate plan id has different format
		terms: NPL_WINBACK_TERMS,
	},
	{
		planId: 'SVOD_STANDARD_12M_LTC',
		terms: LTC_12_MAANDEN,
	},
	{
		planId: 'SVOD_STANDARD_6M_3EUR_DISCOUNT',
		terms: VRIENDEN_LOTERIJ_6_MAANDEN,
	},
	{
		planId: 'Essential12M2EurDiscount',
		terms: NPL_2023,
	},
	{
		planId: 'Plus12M2EurDiscount',
		terms: NPL_2023,
	},
	{
		planId: 'PlusTermed12M1EurDiscount',
		terms: ANNUAL_SUBSCRIPTION_PROMOTION_2023,
	},
	{
		planId: 'PremiumTermed12M1EurDiscount',
		terms: ANNUAL_SUBSCRIPTION_PROMOTION_2023,
	},
	{
		planId: 'PlusLongTermContract3M',
		terms: MKT_SUMMER_OFFER,
	},
	{
		planId: 'Standard3M-50Percent',
		terms: MKT_SUMMER_OFFER_50_PERCENT,
	},
	{
		planId: 'Plus6M2EurDiscount',
		terms: FALL_OFFER_CANCELLABLE,
	},
	{
		planId: 'Premium6M2EurDiscountNonCancellable',
		terms: FALL_OFFER_NON_CANCELLABLE,
	},
	{
		planId: 'Plus6M2EurDiscountNonCancellable',
		terms: FALL_OFFER_NON_CANCELLABLE,
	},
];
