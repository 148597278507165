import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ROUTE_ERROR } from '../constants/Routes';
import useSearchParams from './useSearchParams';
import { PARAM_NAMES } from '../constants/Checkout';
import { analyticsPush } from '../actions/applicationActions';
import { EVT_SHOW_ERROR } from '../constants/EventTypes';
import { useHistory } from 'react-router';
import { getAccountClient } from '../api/accountService';
import { useMutation } from '@apollo/client';
import mutationFixBedrockSubs from '../graphql/mutationFixBedrockSubs.graphql';
import { SECOND } from '../constants/Time';
import { useTimeout } from './useTimeout';
import { autoLoginBR } from './useRedirectUser';
import { useSelector } from 'react-redux';
import { ROUTE_MESSAGES_NEW_PLATFORM } from '../constants/Routes';
import { isOnFreeTier } from '../helpers/tiers';
import { getCurrrentPlan } from '../helpers/state';

const videolandAccountApiClient = getAccountClient();

export function useHandleRedirectReason({ skip }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { getSearchParam } = useSearchParams();
	const [isCalledBRWebhook, setIsCalledBRWebhook] = useState(false);
	const { BEDROCK_REDIRECT_REASON } = PARAM_NAMES;
	const bedrockRedirectReasonQueryParam = getSearchParam(BEDROCK_REDIRECT_REASON);
	const activeSubscription = useSelector((state) => state.subscription.details.data);
	const { expires } = useSelector((state) => state.authentication);
	const currentPlan = useSelector(getCurrrentPlan);
	const tierId = currentPlan?.planDescription.tierId;
	const isUserOnFreeTier = isOnFreeTier(tierId);
	const tier = useSelector((state) => state.subscription.tier);

	const loading = !tier.pristine || !tier.loading || !skip;

	const [fixBedrockSubs] = useMutation(mutationFixBedrockSubs, {
		client: videolandAccountApiClient,
	});

	useTimeout(() => {
		if (isCalledBRWebhook) {
			autoLoginBR(expires);
			history.replace(ROUTE_MESSAGES_NEW_PLATFORM);
		}
	}, 3 * SECOND);

	useEffect(() => {
		const pushAnalytics = (redirectReason) => {
			dispatch(
				analyticsPush({
					events: {
						category: 'error',
						action: 'redirect_reason',
						label: redirectReason,
					},
					event: EVT_SHOW_ERROR,
				})
			);
		};

		if (
			activeSubscription?.status?.toUpperCase() === 'ACTIVE' &&
			bedrockRedirectReasonQueryParam &&
			!loading &&
			!isUserOnFreeTier && //patch since bedrock can not add on a short time a new query param for tv gemist and they redirect to us with no_subscription
			bedrockRedirectReasonQueryParam === 'no_subscription'
		) {
			fixBedrockSubs({}).then(({ data }) => {
				if (data?.fixBedrockSubscription) {
					setIsCalledBRWebhook(true);
				}
			});
		}

		if (
			bedrockRedirectReasonQueryParam &&
			bedrockRedirectReasonQueryParam !== 'subscription_management_requested' &&
			bedrockRedirectReasonQueryParam !== 'no_subscription' &&
			bedrockRedirectReasonQueryParam !== 'logout_requested'
		) {
			pushAnalytics(bedrockRedirectReasonQueryParam);
			history.replace(ROUTE_ERROR);
		}
	}, [
		activeSubscription,
		bedrockRedirectReasonQueryParam,
		dispatch,
		fixBedrockSubs,
		history,
		isUserOnFreeTier,
		loading,
	]);
}
