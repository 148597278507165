/**
 * Enum for Optanon Consent categories
 * @typedef OptanonConsentCategory
 * @readonly
 * @enum {string}
 */
export const OptanonConsentCategory = {
	NATIVE: 'C0001',
	FUNCTIONALLY_REQUIRED: 'C0002',
	ANALYTICS: 'C0003',
	ADVERTISING_AND_TRACKING: 'C0004',
	SOCIAL_MEDIA: 'C0005',
	BG6_UNKNOWN: 'BG6',
};

export const DEFAULT_ENABLED_CATEGORIES = [
	OptanonConsentCategory.NATIVE,
	OptanonConsentCategory.FUNCTIONALLY_REQUIRED,
];
