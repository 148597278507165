import React, { PureComponent } from 'react';
import loadable from '@loadable/component';

export default class PaymentNotification extends PureComponent {
	render() {
		const { isPaid } = this.props;

		let Component = isPaid
			? loadable(() => import('./PaymentPaidNotification'))
			: loadable(() => import('./PaymentPendingNotification'));

		return <Component {...this.props} />;
	}
}
