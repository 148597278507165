import React from 'react';
import { getImageUrl } from '../../../../config';

import {
	ONBOARDING_HELP_FACEBOOK,
	ONBOARDING_HELP_SOCIAL_TIME,
	ONBOARDING_HELP_TELEPHONE,
	ONBOARDING_HELP_TELEPHONE_TIME,
	ONBOARDING_HELP_TWITTER,
} from '../../../../constants/Copy';

import './HelpList.scss';

const phoneIcon = getImageUrl('/images/onboarding/phone-icon.svg');
const facebookIcon = getImageUrl('/images/onboarding/facebook-icon.svg');
const twitterIcon = getImageUrl('/images/onboarding/twitter-icon.svg');

export default () => (
	<ul className="help-list">
		<li className="help-list__list-item">
			<img className="help-list__list-icon" alt="facebook" src={facebookIcon} />
			<a
				target="_blank"
				id="facebook"
				className="help-list__channel-text"
				href="https://nl-nl.facebook.com/Videoland/"
				rel="noopener"
			>
				{ONBOARDING_HELP_FACEBOOK}
			</a>
			<span className="help-list__time">{ONBOARDING_HELP_SOCIAL_TIME}</span>
		</li>
		<li className="help-list__list-item">
			<img className="help-list__list-icon" alt="twitter" src={twitterIcon} />
			<a
				target="_blank"
				id="twitter"
				className="help-list__channel-text"
				href="https://twitter.com/videoland"
				rel="noopener"
			>
				{ONBOARDING_HELP_TWITTER}
			</a>
			<span className="help-list__time">{ONBOARDING_HELP_SOCIAL_TIME}</span>
		</li>
		<li className="help-list__list-item">
			<img className="help-list__list-icon" alt="phone" src={phoneIcon} />
			<a
				target="_blank"
				id="telefoon"
				className="help-list__channel-text"
				href="tel:0881232456"
				rel="noopener"
			>
				{ONBOARDING_HELP_TELEPHONE}
			</a>
			<span className="help-list__time">{ONBOARDING_HELP_TELEPHONE_TIME}</span>
		</li>
	</ul>
);
