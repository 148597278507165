import React from 'react';
import loadable from '@loadable/component';
import { addReducer } from '../../../../common/store';

export const AccountBedrock = (props) => {
	const AccountComponent = loadable(() => import('./AccountBedrock.container'));

	import('../../../reducers/devices/devices').then((reducer) =>
		addReducer('devices', reducer.default)
	);
	import('../../../reducers/subscription/subscription').then((reducer) =>
		addReducer('subscription', reducer.default)
	);
	return <AccountComponent {...props} />;
};
