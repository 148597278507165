import { generatePath } from 'react-router';
import { DEFAULT_RATEPLAN_SLUG } from '../constants/Payment';
import { ROUTE_ACTIVATE_PLAN } from '../constants/Routes';

export const isAbsolute = (url) => {
	return new RegExp('^(?:[a-z]+:)?//', 'i').test(url);
};

export const getRateplanUrl = (plan = DEFAULT_RATEPLAN_SLUG, actiecode) => {
	let redirectUrl = generatePath(ROUTE_ACTIVATE_PLAN, { plan });
	if (actiecode) redirectUrl += `?actiecode=${actiecode}`;
	return redirectUrl;
};

export const removeDomain = (str) => {
	if (!str || typeof str !== 'string') {
		return null;
	}
	return str.replace(/(https?:)?\/\/[^/]+/i, '');
};

export const removeQueryString = (str) => {
	if (!str || typeof str !== 'string') {
		return null;
	}

	return str.split('?')[0];
};
