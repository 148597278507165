import { useEffect } from 'react';
import { reportCustomError } from '../helpers/realUserMonitoring';
import { hasValidVideolandCookie } from '../helpers/authentication';
import { useQuery } from '@apollo/client';
import querySubscriptionTier from '../graphql/querySubscriptionTier.graphql';
import { getSubscriptionClient } from '../api/subscriptionService';
import { useDispatch } from 'react-redux';
import { setSubscriptionTier } from '../actions/profileActions';
import { useLocation } from 'react-router';
import { ROUTE_LOGOUT } from '../constants/Routes';

const subscriptionClient = getSubscriptionClient();

export const useSubscriptionTier = () => {
	const dispatch = useDispatch();
	const isLoggedIn = hasValidVideolandCookie();
	const { pathname } = useLocation();

	const { data, loading, error } = useQuery(querySubscriptionTier, {
		client: subscriptionClient,
		fetchPolicy: 'no-cache',
		skip: !isLoggedIn || pathname === ROUTE_LOGOUT,
	});

	useEffect(() => {
		if (data) {
			dispatch(setSubscriptionTier(data));
		}
	}, [dispatch, data]);

	useEffect(() => {
		if (error) {
			reportCustomError({
				name: 'useSubscriptionTier',
				error,
			});
		}
	}, [error]);

	return { loading, error, data };
};
