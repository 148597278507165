import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { analyticsPush } from '../../../actions/applicationActions';
import { EVT_PAYMENT_REMINDER_CLICKED_PAY } from '../../../constants/EventTypes';
import PaymentReminderNotification from './notifications/paymentNotification.loadable';
import './PaymentReminder.scss';
import { useInvoices } from '../../../hooks/useInvoices';

export const PAYMENT_STATUS = {
	OPEN: 'OPEN',
	PAID: 'PAID',
};

const PaymentReminder = ({ appearance, isSticky }) => {
	const dispatch = useDispatch();
	const { openPayments } = useInvoices();

	function getNotificationProps(payment) {
		const isPaid = payment.status === PAYMENT_STATUS.PAID;

		let props = {
			appearance,
			hasCloseButton: isPaid,
			isPaid: isPaid,
		};

		if (!isPaid) {
			props = {
				...props,
				dueDate: payment.dueDate,
				onPayClick: handlePayClick,
				payLink: payment.payLink,
			};
		}

		return props;
	}

	const handlePayClick = useCallback(
		(payLink, label) => {
			window.open(payLink);
			dispatch(analyticsPush({ label }, EVT_PAYMENT_REMINDER_CLICKED_PAY));
		},
		[dispatch]
	);

	function renderReminders() {
		return openPayments
			? openPayments.map((payment, index) => {
					if (!payment.status || !payment.payLink) {
						return null;
					}
					const notificationProps = getNotificationProps(payment, index);
					return <PaymentReminderNotification key={`reminder-${index}`} {...notificationProps} />;
			  })
			: null;
	}

	if (openPayments?.length === 0) {
		return null;
	}

	return isSticky ? (
		<div className="payment-reminder__is-sticky">{renderReminders()}</div>
	) : (
		renderReminders()
	);
};

export default PaymentReminder;
