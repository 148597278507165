import { TIER_FEATURE_IDS, TIER_IDS } from './Tiers';

export const TierFeatures = {
	[TIER_FEATURE_IDS.UNLIMITED_ACCESS]: {
		title: 'Onbeperkt toegang tot alle series, films en programma’s',
		values: {
			[TIER_IDS.PREMIUM]: true,
			[TIER_IDS.STANDARD]: true,
			[TIER_IDS.ESSENTIAL]: true,
		},
	},
	[TIER_FEATURE_IDS.AD_FREE]: {
		title: 'Reclamevrij',
		values: {
			[TIER_IDS.PREMIUM]: true,
			[TIER_IDS.STANDARD]: true,
			[TIER_IDS.ESSENTIAL]: false,
		},
	},
	[TIER_FEATURE_IDS.SCREENS_AT_ONCE]: {
		title: 'Schermen tegelijk',
		values: {
			[TIER_IDS.PREMIUM]: 4,
			[TIER_IDS.STANDARD]: 2,
			[TIER_IDS.ESSENTIAL]: 1,
		},
	},
	[TIER_FEATURE_IDS.DOWNLOAD_TO_GO]: {
		title: 'Download-to-go',
		values: {
			[TIER_IDS.PREMIUM]: true,
			[TIER_IDS.STANDARD]: true,
			[TIER_IDS.ESSENTIAL]: false,
		},
	},
	[TIER_FEATURE_IDS.LIVE_TV]: {
		title: 'Live TV',
		values: {
			[TIER_IDS.PREMIUM]: true,
			[TIER_IDS.STANDARD]: true,
			[TIER_IDS.ESSENTIAL]: true,
		},
	},
};

export const FreeTierFeatures = {
	description:
		'Met <strong>TV Gemist</strong> kijk je je favoriete RTL programma’s tot 7 dagen  terug',
	features: [
		{
			title: 'Via de Videoland website of app',
			value: true,
		},
		{
			title: 'Zonder betaald abonnement, je zit nergens aan vast',
			value: true,
		},
		{
			title: 'Scherm tegelijk',
			value: 1,
		},
		{
			title: 'Onbeperkt toegang tot alle series, films en RTL programma’s',
			value: false,
		},
		{
			title: 'Reclamevrij, download to go, RTL programma’s vooruit en live kijken',
			value: false,
		},
	],
};
