export const DEVICES_LOAD = 'DEVICES_LOAD';
export const DEVICE_ADD = 'DEVICE_ADD';
export const DEVICE_ERROR_RESET = 'DEVICE_ERROR_RESET';
export const DEVICE_REMOVE = 'DEVICE_REMOVE';
export const PAYMENT_ACCOUNT = 'PAYMENT_ACCOUNT';
export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL';
export const SUBSCRIPTION_REACTIVATE = 'SUBSCRIPTION_REACTIVATE';
export const SET_SUBSCRIPTION_TIER = 'SET_SUBSCRIPTION_TIER';
export const SUBSCRIPTION_FETCH_TIER = 'SUBSCRIPTION_FETCH_TIER';
export const SUBSCRIPTION_CHANGE_TIER = 'SUBSCRIPTION_CHANGE_TIER';
export const SUBSCRIPTIONS_FETCH = 'SUBSCRIPTIONS_FETCH';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SUBSCRIPTION_CLEAR = 'SUBSCRIPTION_CLEAR';
export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM';
export const ANALYTICS_PUSH = 'ANALYTICS_PUSH';
export const HEARTBEAT = 'HEARTBEAT';
export const GEO_CHECK = 'GEO_CHECK';
export const COOKIE_CONFIG = 'COOKIE_CONFIG';
export const VIDEOLAND_LOGIN = 'VIDEOLAND_LOGIN';
export const ACCOUNT_RESET_PASSWORD = 'ACCOUNT_RESET_PASSWORD';
export const ACCOUNT_CHECK_RESET_TOKEN = 'ACCOUNT_CHECK_RESET_TOKEN';
export const ACCOUNT_UPDATE_PASSWORD = 'ACCOUNT_UPDATE_PASSWORD';
export const OVERWRITE_UID = 'OVERWRITE_UID';
export const OVERWRITE_REDIRECT_URL = 'OVERWRITE_REDIRECT_URL';
export const CLEAR_REDIRECT_URL = 'CLEAR_REDIRECT_URL';
export const DEFER_LOGIN = 'DEFER_LOGIN';
export const DEFER_LOGIN_RESET = 'DEFER_LOGIN_RESET';
export const GIGYA_CLOSE_POPUP = 'GIGYA_CLOSE_POPUP';
export const GIGYA_INIT_REGISTRATION = 'GIGYA_INIT_REGISTRATION';
export const GIGYA_REGISTER = 'GIGYA_REGISTER';
export const GIGYA_GET_ACCOUNT_INFO = 'GIGYA_GET_ACCOUNT_INFO';
export const GIGYA_SET_ACCOUNT_INFO = 'GIGYA_SET_ACCOUNT_INFO';
export const GIGYA_LOGIN = 'GIGYA_LOGIN';
export const GIGYA_VERIFY_LOGIN = 'GIGYA_VERIFY_LOGIN';
export const GIGYA_LOGOUT = 'GIGYA_LOGOUT';
export const GIGYA_SOCIAL_LOGIN = 'GIGYA_SOCIAL_LOGIN';
export const GIGYA_LINK_ACCOUNT = 'GIGYA_LINK_ACCOUNT';
export const IDENTITY_SERVICE_LOGOUT = 'IDENTITY_SERVICE_LOGOUT';
export const IDENTITY_SERVICE_VERIFICATION_CODE = 'IDENTITY_SERVICE_VERIFICATION_CODE';
export const RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE';
export const GIGYA_FINALIZE_REGISTRATION = 'GIGYA_FINALIZE_REGISTRATION';
export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE'; // Due to https://github.com/erikras/redux-form/issues/3973
export const HELP_OPEN = 'HELP_OPEN';
export const HELP_CLOSE = 'HELP_CLOSE';
export const RATEPLAN_LOAD = 'RATEPLAN_LOAD';
export const RATEPLANS_LOAD = 'RATEPLANS_LOAD';
export const RATEPLAN_VOUCHER_CHECK = 'RATEPLAN_VOUCHER_CHECK';
export const BANKS_LOAD = 'BANKS_LOAD';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const USER_RATEPLANS_LOAD = 'USER_RATEPLANS_LOAD';
export const USER_RATEPLANS = 'USER_RATEPLANS';
export const ACCOUNT_CLEAR = 'ACCOUNT_CLEAR';
export const PROMO_DATA_LOAD = 'PROMO_DATA_LOAD';
export const TIERS_FETCH = 'TIERS_FETCH';
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';
export const FETCH_OPEN_PAYMENTS = 'FETCH_OPEN_PAYMENTS';
export const GET_OPEN_PAYMENTS = 'GET_OPEN_PAYMENTS';
export const FETCH_SUBSCRIBE_NEWSLETTER = 'FETCH_SUBSCRIBE_NEWSLETTER';
export const SUBSCRIBE_NEWSLETTER = 'SUBSCRIBE_NEWSLETTER';
export const CANCEL_SUBSCRIPTION_NEWSLETTER = 'CANCEL_SUBSCRIPTION_NEWSLETTER';
export const VERIFY_NUMBER = 'VERIFY_NUMBER';
export const VERIFY_CODE = 'VERIFY_CODE';
export const TIERS_SIDEBAR_TOGGLE = 'TIERS_SIDEBAR_TOGGLE';
