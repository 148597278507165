export const svgPaths = {
	checkmark: [
		{
			path: 'M12.0706646,18.313709 C12.0342243,18.350129 11.9967285,18.3848496 11.9582756,18.4178707 C11.1725347,19.0930577 9.98685408,19.0583245 9.24223809,18.3137085 C9.23084595,18.3023164 9.21961997,18.290821 9.20856016,18.2792254 L3.58578644,12.6564517 C2.80473785,11.8754031 2.80473785,10.6090731 3.58578644,9.82802453 C4.36683502,9.04697594 5.63316498,9.04697594 6.41421356,9.82802453 L10.6568542,14.0706652 L19.141733,5.58578644 C19.9227816,4.80473785 21.1891116,4.80473785 21.9701602,5.58578644 C22.7512087,6.36683502 22.7512087,7.63316498 21.9701602,8.41421356 L12.0706652,18.3137085 L12.0706646,18.313709 Z',
		},
	],
	exclamation_mark: [
		{
			path: 'M15.6160173,3 C16.4116668,3 17.1747285,3.31607052 17.7373377,3.87867966 L20.1213203,6.26266234 C20.6839295,6.82527147 21,7.58833321 21,8.38398268 L21,15.6160173 C21,16.4116668 20.6839295,17.1747285 20.1213203,17.7373377 L17.7373377,20.1213203 C17.1747285,20.6839295 16.4116668,21 15.6160173,21 L8.38398268,21 C7.58833321,21 6.82527147,20.6839295 6.26266234,20.1213203 L3.87867966,17.7373377 C3.31607052,17.1747285 3,16.4116668 3,15.6160173 L3,8.38398268 C3,7.58833321 3.31607052,6.82527147 3.87867966,6.26266234 L6.26266234,3.87867966 C6.82527147,3.31607052 7.58833321,3 8.38398268,3 L15.6160173,3 Z M15.6160173,5 L8.38398268,5 C8.11876619,5 7.86441228,5.10535684 7.6768759,5.29289322 L5.29289322,7.6768759 C5.10535684,7.86441228 5,8.11876619 5,8.38398268 L5,15.6160173 C5,15.8812338 5.10535684,16.1355877 5.29289322,16.3231241 L7.6768759,18.7071068 C7.86441228,18.8946432 8.11876619,19 8.38398268,19 L15.6160173,19 C15.8812338,19 16.1355877,18.8946432 16.3231241,18.7071068 L18.7071068,16.3231241 C18.8946432,16.1355877 19,15.8812338 19,15.6160173 L19,8.38398268 C19,8.11876619 18.8946432,7.86441228 18.7071068,7.6768759 L16.3231241,5.29289322 C16.1355877,5.10535684 15.8812338,5 15.6160173,5 Z M12,14.75 C12.6903559,14.75 13.25,15.3096441 13.25,16 C13.25,16.6903559 12.6903559,17.25 12,17.25 C11.3096441,17.25 10.75,16.6903559 10.75,16 C10.75,15.3096441 11.3096441,14.75 12,14.75 Z M12,7 C12.5522847,7 13,7.44771525 13,8 L13,12 C13,12.5522847 12.5522847,13 12,13 C11.4477153,13 11,12.5522847 11,12 L11,8 C11,7.44771525 11.4477153,7 12,7 Z',
		},
	],
	users: [
		{
			path: 'M16.538 15.774v-.649c0-.923-.461-1.693-1.18-2.29.5-.129.997-.196 1.371-.196 1.146 0 3.438.627 3.438 1.742v.696c0 .415-.258.697-.688.697h-2.94zm-5.729-3.149c1.528 0 4.583.875 4.583 2.5v1.25c0 .231-.343.625-.573.625H6.8c-.23 0-.573-.394-.573-.625v-1.25c0-1.625 3.055-2.5 4.583-2.5zm-5.538.014c.3 0 .679.043 1.075.127-.768.606-1.266 1.4-1.266 2.359v.65H2.52c-.429 0-.687-.283-.687-.698v-.696c0-1.115 2.292-1.742 3.438-1.742zM16.729 8.25c.95 0 1.719.842 1.719 1.881 0 1.039-.77 1.881-1.719 1.881-.95 0-1.719-.842-1.719-1.881 0-1.039.77-1.881 1.72-1.881zm-11.458 0c.95 0 1.719.842 1.719 1.881 0 1.039-.77 1.881-1.72 1.881-.948 0-1.718-.842-1.718-1.881 0-1.039.77-1.881 1.719-1.881zM10.809 7l.157.006c1.192.088 2.135 1.17 2.135 2.494 0 1.38-1.026 2.5-2.292 2.5-1.266 0-2.292-1.12-2.292-2.5S9.543 7 10.81 7z',
		},
	],
	times: [
		{
			path: 'M14.955 12l4.433 4.433a2.09 2.09 0 01-2.955 2.955L12 14.955l-4.433 4.433a2.09 2.09 0 01-2.955-2.955L9.045 12 4.612 7.567a2.09 2.09 0 112.955-2.955L12 9.045l4.433-4.433a2.09 2.09 0 112.955 2.955L14.955 12z',
		},
	],
	more_info: [
		{
			path: 'M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 2a8 8 0 100 16 8 8 0 000-16zm1.153 6c.507 0 .72.256.72.577 0 .266-.113.605-.308 1.155l-.216.607-.883 2.45c-.213.593-.262.817-.262.978 0 .112.05.224.213.224.327 0 1.112-.85 1.21-.993a.088.088 0 01.101-.034l.03.018.196.192c.05.048.065.08.016.16l-.07.102A3.855 3.855 0 018.782 15c-.49 0-.638-.24-.638-.48l.006-.086c.028-.21.149-.586.385-1.264l.574-1.63.554-1.592c.082-.247.133-.433.133-.57 0-.113-.066-.209-.213-.209-.262 0-.998.817-1.194 1.074-.052.05-.104.061-.148.039l-.032-.023-.147-.145c-.049-.048-.082-.112-.049-.144l.13-.167C8.525 9.323 9.683 8 11.153 8zm-.22-3c.721 0 1.067.41 1.067.918S11.519 7 10.962 7C10.376 7 10 6.623 10 6c0-.508.421-1 .932-1z',
		},
	],
	back_arrow: [
		{
			path: 'M5.449.244l.003.004a.743.743 0 010 1.047L2.596 4.168h9.946a.74.74 0 010 1.482L2.49 5.649l2.96 2.98a.743.743 0 010 1.047.734.734 0 01-1.037.003l-.003-.003L.249 5.489a.743.743 0 01-.003-1.05L4.411.247A.734.734 0 015.449.244z',
		},
	],
	download_icon: [
		{
			path: 'M5.6 11.448V1.333C5.6.597 6.227 0 7 0c.773 0 1.4.597 1.4 1.333v10.115l3.21-3.057a1.45 1.45 0 011.98 0c.547.52.547 1.364 0 1.885l-5.6 5.333a1.45 1.45 0 01-1.98 0l-5.6-5.333a1.289 1.289 0 010-1.885 1.45 1.45 0 011.98 0l3.21 3.057z',
		},
		{
			rect: { y: 18, width: 14, height: 2.5, rx: 1.25 },
		},
	],
};
