import React from 'react';
import { Route } from 'react-router-dom';
import { isAllowedToEnter } from '../helpers/authentication';
import HelmetDefault from '../components/common/helmet/HelmetDefault';
import PaymentReminder from '../components/account/paymentReminder/PaymentReminder';
import { NOTIFICATION_APPEARANCE_WIDE } from '../components/common/notification/Notification';
import HeaderBedrock from '../components/header/HeaderBedrock';
import Layout from '../components/layout/Layout';

export default ({
	component: Component,
	onEnter = [],
	path,
	exact,
	hasPaymentReminder,
	...restProps
}) => {
	return (
		<Route
			path={path}
			exact={exact}
			render={(props) => {
				const {
					history,
					location: { pathname },
				} = props;

				if (!isAllowedToEnter(onEnter, history)) return null;

				return (
					<Layout hideFooter={props.hideFooter}>
						<HelmetDefault path={pathname} />
						<HeaderBedrock />
						<Component {...props} {...restProps} />
						{hasPaymentReminder && (
							<PaymentReminder isSticky appearance={NOTIFICATION_APPEARANCE_WIDE} />
						)}
					</Layout>
				);
			}}
		/>
	);
};
