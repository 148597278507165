import { useEffect } from 'react';
import { reportCustomError } from '../helpers/realUserMonitoring';
import { hasValidVideolandCookie } from '../helpers/authentication';
import { useQuery } from '@apollo/client';
import querySubscription from '../graphql/querySubscription.graphql';
import { getSubscriptionClient } from '../api/subscriptionService';
import { useDispatch, useSelector } from 'react-redux';
import { setSubscription } from '../actions/profileActions';
import { ROUTE_EMAIL_VERIFIED, ROUTE_LOGOUT } from '../constants/Routes';
import { redirectIfNoActiveSubscription } from '../helpers/redirect';
import { useLocation } from 'react-router';

const videolandSubscriptionApiClient = getSubscriptionClient();

export const useSubscription = () => {
	const dispatch = useDispatch();
	const isLoggedIn = hasValidVideolandCookie();
	const router = useSelector((state) => state.router);
	const redirectUrl = useSelector((state) => state.authentication.redirectUrl);
	const activeSubscriptionStatus = useSelector((state) => state.subscription.details);
	const { pathname } = useLocation();

	const { data, loading, error } = useQuery(querySubscription, {
		client: videolandSubscriptionApiClient,
		fetchPolicy: 'no-cache',
		skip: !isLoggedIn || pathname === ROUTE_LOGOUT,
	});

	useEffect(() => {
		if (data && !loading) {
			dispatch(setSubscription(data));
		}
	}, [dispatch, data, loading]);

	useEffect(
		() => {
			if (activeSubscriptionStatus?.data) {
				if (router?.location.pathname !== ROUTE_EMAIL_VERIFIED) {
					redirectIfNoActiveSubscription({
						subscriptions: activeSubscriptionStatus.data,
						router,
						redirectUrl,
					});
				}
			}
		},
		//if we add router that is a missing dependencies redirectIfNoActiveSubscription create an infinite loop
		[activeSubscriptionStatus, redirectUrl]
	);

	useEffect(() => {
		if (error) {
			reportCustomError({
				name: 'useSubscription',
				error,
			});
		}
	}, [error]);

	return {
		data,
		loading,
		error,
		userHasSubscription: data?.me?.subscription?.status.toLowerCase() === 'active',
	};
};
