import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { svgPaths } from '../../../constants/Icons';
import './Icon.scss';

export default function Icon({
	name,
	title,
	className,
	viewBox = '0 0 100 100',
	width = 20,
	height = 20,
	pathProps,
}) {
	const iconClassName = cx('svg-icon', { [className]: !!className });
	const paths = svgPaths[name];

	if (!paths) return null;

	return (
		<svg
			aria-label={title}
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox={viewBox}
			className={iconClassName}
		>
			{title && <title className="svg-icon_title">{title}</title>}
			<g fill="currentColor">{paths.map(renderPath)}</g>
		</svg>
	);

	function renderPath({ path, rect, defaultPathProps = {} }, idx) {
		if (rect) {
			return <rect key={`${name}_${idx}`} {...rect} />;
		}
		return <path key={`${name}_${idx}`} d={path} {...{ ...defaultPathProps, ...pathProps }} />;
	}
}

Icon.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
};
