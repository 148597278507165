export const PARAM_NAMES = {
	ENTITLEMENT_ID: 'entitlementId',
	HAS_SUSPENDED_SUBSCRIPTION: 'hasSuspendedSubscription',
	THIRD_PARTY_ACTIVATE_PRECONDITION: 'activatePrecondition',
	TRANSACTION_ID: 'transactionId',
	VOUCHERCODE: 'actiecode',
	CHANGE_PAYMENT_DETAILS_STATUS: 'status',
	XLTOVL: 'xlToVl',
	VOUCHER_RATE_PLAN_CODE: 'VOUCHER',
	BEDROCK_REDIRECT_REASON: 'redirect_reason',
	PLAN_ID: 'planId',
	REDIRECT_URL: 'redirectUrl',
	RESELLER: 'reseller',
	REFERRAL: 'referral'
};
