import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../../icons/Icon';
import OnboardingButton from '../onboarding/OnboardingButton';

import './IconButton.scss';

const IconButton = ({ className, iconName, width = 16, height = 16, text, ...restProps }) => {
	const classNames = cx('icon-button', {
		[className]: !!className,
	});

	return (
		<OnboardingButton data-test="icon-button" className={classNames} {...restProps}>
			<Icon
				className="icon-button__icon"
				name={iconName}
				title={text}
				width={width}
				height={height}
				viewBox={`4 4 ${width} ${height}`}
			/>
		</OnboardingButton>
	);
};

IconButton.propTypes = {
	className: PropTypes.string,
	height: PropTypes.string,
	iconName: PropTypes.string.isRequired,
	title: PropTypes.string,
	width: PropTypes.string,
};

export default React.memo(IconButton);
