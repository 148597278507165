import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import './EmailVerified.scss';
import Heading from '../../../common/heading/Heading';
import {
	ONBOARDING_EMAIL_VERIFIED_SUCCESS,
	ONBOARDING_EMAIL_VERIFIED_TEXT,
	ONBOARDING_EMAIL_VERIFIED_TITLE,
} from '../../../../constants/Copy';
import { stateRender } from '../../../../helpers/react';
import { isConnectPartnerProcessingRoute } from '../../../../helpers/connectPartner';
import { getExternalUrl } from '../../../../config';
import { DEFAULT_RATEPLAN_SLUG } from '../../../../constants/Payment';
import { getTierRateplanFromUrl, isVoucherCodeInRedirectUrl } from '../../../../helpers/redirect';
import { EmailVerifiedProceedButton } from './EmailVerifiedProceedButton';
import EmailVerifiedTierDisplayLoadable from './EmailVerifiedTierDisplay.loadable';
import OnboardingProgress from '../../common/onboardingProgress/OnboardingProgress.container';

const EmailVerified = (props) => {
	const history = useHistory();
	const gigyaRedirectUrl = props.gigyaUser?.data?.redirectUrl;
	const gigyaUserData = props.gigyaUser?.data;
	const { dispatchFetchTiers, useHandleEmailVerification, useVideolandAccountState } = props;

	const isVoucherCodeInRedirect = isVoucherCodeInRedirectUrl(gigyaRedirectUrl);
	const tierRateplanFromRedirectUrl = getTierRateplanFromUrl(gigyaRedirectUrl);

	const [isFetchTiersInitialized, setIsFetchTiersInitialized] = useState(false);
	const { isVerified } = useHandleEmailVerification();
	const { isBlackFriday } = useVideolandAccountState();

	useEffect(() => {
		if (isVerified && gigyaUserData && !isFetchTiersInitialized && !isVoucherCodeInRedirect) {
			dispatchFetchTiers();
			setIsFetchTiersInitialized(true);
		}
	}, [
		dispatchFetchTiers,
		isVerified,
		gigyaUserData,
		isVoucherCodeInRedirect,
		isFetchTiersInitialized,
	]);

	const isRedirectUrlConnectPartnerRoute =
		!!gigyaRedirectUrl && isConnectPartnerProcessingRoute(gigyaRedirectUrl);

	useEffect(() => {
		if (isVerified && isRedirectUrlConnectPartnerRoute) {
			history.replace(gigyaRedirectUrl);
		}
	}, [isVerified, gigyaRedirectUrl, history, isRedirectUrlConnectPartnerRoute]);

	const { isTiersError, isTiersPending, toggleTieringSidebar } = props;

	return stateRender({
		isPending:
			!isVerified ||
			isTiersPending ||
			!gigyaUserData ||
			isRedirectUrlConnectPartnerRoute ||
			isBlackFriday === undefined,
		isError: isTiersError,
		onSuccess: renderContent,
		spinnerModifierClass: 'spinner--onboarding',
	});

	function renderContent() {
		const showTiering =
			!isVoucherCodeInRedirect && (tierRateplanFromRedirectUrl || !gigyaRedirectUrl);

		return (
			<div className="onboarding-email-verified">
				<OnboardingProgress activeStep={3} />
				<Heading>
					{ONBOARDING_EMAIL_VERIFIED_SUCCESS}
					<br />
					{ONBOARDING_EMAIL_VERIFIED_TITLE}
				</Heading>
				{showTiering ? (
					<EmailVerifiedTierDisplayLoadable
						isBlackFriday={isBlackFriday}
						tiers={props.tierCollection}
						proceedUrl={gigyaRedirectUrl}
						selectedRateplanSlug={tierRateplanFromRedirectUrl}
						dispatchAnalyticsPush={props.dispatchAnalyticsPush}
						toggleTieringSidebar={toggleTieringSidebar}
					/>
				) : (
					<>
						<p className="onboarding__text" data-test="email-verified-proceed-title">
							{ONBOARDING_EMAIL_VERIFIED_TEXT}
						</p>
						<EmailVerifiedProceedButton
							proceedUrl={getPreTieringRedirectUrlOrDefault(gigyaRedirectUrl)}
							testId="email-verified-proceed-button"
						/>
					</>
				)}
			</div>
		);
	}

	function getPreTieringRedirectUrlOrDefault(gigyaRedirectUrl) {
		return (
			gigyaRedirectUrl || getExternalUrl('no_subscription', { rateplan: DEFAULT_RATEPLAN_SLUG })
		);
	}
};

EmailVerified.propTypes = {
	useHandleEmailVerification: PropTypes.func.isRequired,
	dispatchFetchTiers: PropTypes.func.isRequired,
	dispatchAnalyticsPush: PropTypes.func.isRequired,
	isTiersPending: PropTypes.bool.isRequired,
	isTiersError: PropTypes.bool.isRequired,
	tierCollection: PropTypes.arrayOf(PropTypes.object),
	gigyaUser: PropTypes.object.isRequired,
};

export default EmailVerified;
