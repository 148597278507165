import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { getConfig } from '../apiConfig';
import fetch from 'isomorphic-fetch';
import { reportError } from '../helpers/realUserMonitoring';
import { SpanKind, trace } from '@opentelemetry/api';

/* This is a fix for older browsers that don't support the full Promise spec and more specific 'finally'.
 * Apollo has a bug where it doesn't compile properly for older browsers. See https://github.com/apollographql/apollo-client/issues/6381
 */
async function loadFinallyPolyfill() {
	const hasSupportForFinally = !!Promise.prototype.finally;

	if (!hasSupportForFinally) {
		await import('core-js/features/promise');
	}
	return Promise.resolve();
}

const defaultApiHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
	'Cache-Control': 'no-cache',
};

export default createGenericApiClientWithDefaults;

function createGenericApiClientWithDefaults(configHostKey, useRestLink = false) {
	return createGenericApiClient(
		configHostKey,
		ApolloClient,
		InMemoryCache,
		fetch,
		reportError,
		useRestLink
	);
}

export function createGenericApiClient(
	configHostKey,
	ApolloClient,
	InMemoryCache,
	fetch,
	reportError,
	useRestLink
) {
	let apolloClientInstance = createApolloClient(configHostKey, useRestLink);

	return { performRequest, getApolloClientInstance: () => apolloClientInstance };

	function performRequest(options, successCallback, errorCallBack) {
		if (options.query || options.mutation) {
			performGraphQLRequest(apolloClientInstance, options, successCallback, errorCallBack);
		} else {
			performRestRequest(options, successCallback, errorCallBack);
		}
	}

	async function performGraphQLRequest(
		apolloClientInstance,
		{ query, mutation, variables, fetchPolicy },
		successCallback,
		errorCallBack
	) {
		let span = undefined;
		let context = undefined;
		try {
			const tracer = trace.getTracer('GenericApiClient-instrumentation');
			span = startSpan(tracer, 'GraphQL request');

			if (span) {
				// Most GraphQL requests are POST requests
				// TODO: Instrument the isomorphic-fetch to get the correct method
				span.setAttribute('http.method', 'POST');
				addUrlAndHostToSpan(span, apolloClientInstance?.link?.options?.uri);

				// context propagates the trace context to the backend
				context = createTraceContext(span);

				addOperationsToSpan(span, query, mutation);
			}

			await loadFinallyPolyfill();
			const client = apolloClientInstance;
			const result = mutation
				? await client.mutate({ mutation, variables, context })
				: await client.query({ query, variables, fetchPolicy, context });

			// if an exception wasn't thrown, the request was successful
			span?.setAttribute('http.status_code', 200);
			successCallback(result);
		} catch (e) {
			addExceptionAttributesToSpan(span, e);
			reportError(e);
			errorCallBack(e);
		} finally {
			endSpan(span);
		}
	}

	async function performRestRequest(
		{ url, method, body, headers },
		successCallback,
		errorCallback
	) {
		try {
			await loadFinallyPolyfill();
			const config = getConfig();
			const base = config[configHostKey];
			const rawResponse = await fetch(base + url, {
				method,
				body: body ? JSON.stringify(body) : undefined,
				credentials: 'include',
				headers: {
					...defaultApiHeaders,
					...headers,
				},
			});
			const parsedResponse = await parseRestResponse(rawResponse);
			successCallback(parsedResponse);
		} catch (e) {
			reportError(e);
			errorCallback(e);
		}
	}

	// Creates a new span
	// We are using a custom create span function because the default createSpan function
	// from the @opentelemetry uses the performance API which has a known bug
	// https://github.com/open-telemetry/opentelemetry-js/pull/3434
	function startSpan(tracer, spanName, context) {
		const dateNow = Date.now();
		return tracer.startSpan(
			spanName,
			{
				startTime: [dateNow / 1000, (dateNow % 1000) * 1000000],
				kind: SpanKind.CLIENT,
			},
			context
		);
	}

	// Ends a span
	// We are using a custom end span function because the default end function
	// from the @opentelemetry uses the performance API which has a known bug
	// https://github.com/open-telemetry/opentelemetry-js/pull/3434
	function endSpan(span) {
		if (!span) return;

		const dateNow = Date.now();
		const endTime = [dateNow / 1000, (dateNow % 1000) * 1000000];
		span?.end(endTime);
	}

	function createTraceContext(span) {
		let context = undefined;
		const spanContext = span?.spanContext();
		if (spanContext) {
			// https://www.w3.org/TR/trace-context/#traceparent-header
			context = { headers: { traceparent: `00-${spanContext.traceId}-${spanContext.spanId}-01` } };
		}

		return context;
	}

	function addOperationsToSpan(span, query, mutation) {
		if (!span) return;

		const definitions = query?.definitions ?? mutation?.definitions;
		if (definitions?.length) {
			let i = 0;
			for (let definition of definitions) {
				if (definition.kind !== 'OperationDefinition') continue;

				span.setAttribute(`graphql.operation_${i}.name`, definition.name?.value);
				span.setAttribute(`graphql.operation_${i}.type`, definition.operation);
				i++;
			}
		}
	}

	function addUrlAndHostToSpan(span, url) {
		if (!span || !url) return;

		span.setAttribute('http.url', url);
		span.setAttribute('http.host', new URL(url).host);
	}

	function addExceptionAttributesToSpan(span, exception) {
		if (!span || !exception) {
			return;
		}
		span.setAttribute(
			'http.status_code',
			exception.response?.status || exception.networkError?.statusCode || 500
		);
		span.setAttribute('error.message', exception.message);
		span.setAttribute('error.stack', exception.stack);
		span.setAttribute('error.type', exception.name);
	}

	function createApolloClient(configHostKey, useRestLink) {
		const config = getConfig();
		if (!config[configHostKey]) throw new Error(`Can't find host with key ${configHostKey}`);

		if (useRestLink) {
			const restLink = new RestLink({
				uri: config[configHostKey],
				credentials: 'include',
				headers: {
					'videoland-platform': 'videoland',
				},
			});

			return new ApolloClient({
				link: restLink,
				cache: new InMemoryCache(),
			});
		}

		return new ApolloClient({
			name: 'apollo_' + configHostKey,
			uri: config[configHostKey] + '/graphql',
			cache: new InMemoryCache(),
			credentials: 'include',
		});
	}
}

async function parseRestResponse(response) {
	if (~[202, 204, 205].indexOf(response.status)) {
		return null;
	} else if (response.status >= 200 && response.status < 300) {
		return await response.json().catch(() => {});
	} else {
		const body = await response.json();
		const err = new Error(body.message);
		err.body = body;
		err.response = response;
		throw err;
	}
}
