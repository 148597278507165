import { genresToGTMFormat, createAnalyticsFlagsValue } from '../helpers/analytics';
import { getTimestamp } from '../helpers/datetime';
import { ANALYTICS_PUSH, DEVICE_ADD, DEVICE_REMOVE } from '../constants/ActionTypes';

import {
	EVT_DEVICE_ADD,
	EVT_DEVICE_ADD_ERROR,
	EVT_DEVICE_REMOVE,
	EVT_DEVICE_REMOVE_PENDING,
	EVT_SUBSCRIPTION_CANCEL,
	EVT_CHANGE_TIER_CANCEL,
	EVT_CHANGE_TIER_SUCCESS,
	EVT_CHANGE_TIER_ERROR,
	EVT_SHOW_ERROR,
	EVT_CHANGE_TIER_NOTIFICATION_SHOW,
	EVT_CHANGE_TIER_NOTIFICATION_HIDE,
	EVT_BUTTON_CLICK,
	EVT_SELECT_TILE,
	EVT_CHANGE_TIER_FROM_UNSUBSCRIBE_CLICK,
	EVT_CHANGE_TIER_CLICK,
	EVT_CHANGE_TIER_SUBMIT,
	EVT_EMAILVERIFIED_SHOW_TIERS,
	EVT_SHOW_TIERS_LABEL,
	EVT_ONBOARDING_CATEGORY,
	EVT_SHOW_TIER_SUMMARY,
	EVT_EMAILVERIFIED_CHANGE_TIER,
	EVT_CHANGE_TIER_SUBSCRIPTION_CATEGORY,
	EVT_CHANGE_TIER_NOTIFICATION_CATEGORY,
	EVT_CHANGE_TIER_PROFILE_CATEGORY,
	EVT_CHANGE_TIER_FUNNEL_NAME,
	EVT_TIER_OPTION_CONFIRM,
	EVT_TIER_OPTION_SELECT,
	EVT_CONCURRENCY_ERROR,
	EVT_SHOW_NOTIFICATION,
	EVT_PAYMENT_REMINDER_SHOWN,
	EVT_PAYMENT_REMINDER_SHOWN_LABEL,
	EVT_PAYMENT_REMINDER_SHOWN_ACTION,
	EVT_PAYMENT_REMINDER_CLICKED_PAY,
	EVT_PAYMENT_REMINDER_CLICKED_PAY_ACTION,
	EVT_PAYMENT_REMINDER_SUCCESS_SHOWN,
	EVT_PAYMENT_REMINDER_SUCCESS_SHOWN_LABEL,
	EVT_PAYMENT_REMINDER_SUCCESS_SHOWN_ACTION,
	EVT_PAYMENT_REMINDER_SUCCESS_CLICKED_CLOSED,
	EVT_PAYMENT_REMINDER_SUCCESS_CLICKED_CLOSED_ACTION,
	EVT_PAYMENT_REMINDER_SHOWN_ERROR,
	EVT_PAYMENT_REMINDER_SHOWN_ERROR_LABEL,
	EVT_PAYMENT_REMINDER_SHOWN_ERROR_ACTION,
	EVT_PAYMENT_REMINDER_FUNNEL_NAME,
	EVT_PAYMENT_REMINDER_NOTIFICATION_CATEGORY,
	EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE,
	EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE_LABEL,
	EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE_ACTION,
	EVT_SESSION_START,
	EVT_SESSION_START_EVENT_NAME,
	EVT_INITIAL_PAGE,
	EVT_INITIAL_PAGE_NAME,
	EVT_LINK_CLICK,
	EVT_CLICKED_PAY_OPEN_INVOICE,
	EVT_CLICKED_PAY_OPEN_INVOICE_CATEGORY,
	EVT_CLICKED_PAY_OPEN_INVOICE_ACTION,
	EVT_CLICKED_PAY_OPEN_INVOICE_EVENT,
	EVT_CLICKED_PAY_OPEN_INVOICE_FUNNEL_NAME,
	EVT_NEWSLETTER_TOGGLE,
	EVT_NEWSLETTER_CATEGORY,
	EVT_CLICKED_CHANGE_PAYMENT_DETAILS,
	EVT_CLICKED_CHANGE_PAYMENT_DETAILS_CATEGORY,
	EVT_CLICKED_CHANGE_PAYMENT_DETAILS_ACTION,
	EVT_CLICKED_CHANGE_PAYMENT_DETAILS_EVENT,
	EVT_CLICKED_CHANGE_PAYMENT_DETAILS_FUNNEL_NAME,
	EVT_CLICKED_PAYMENT_METHOD,
	EVT_FUNNEL_STEP_ONE,
	EVT_FUNNEL_STEP_TWO,
	EVT_FUNNEL_STEP_THREE,
	EVT_FUNNEL_STEP_FOUR,
	EVT_FLAG_ALLOCATED,
	EVT_FLAG_ALLOCATED_EVENT,
	EVT_FLAG_ALLOCATED_CATEGORY,
	EVT_ONBOARDING_SUBMIT_GENDER,
	EVT_ACTION_SUBMIT_GENDER,
	EVT_ONBOARDING_SKIP_GENDER,
	EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_SUCCESS,
	EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_CATEGORY,
	EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_ACTION,
	EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_SUCCESS_LABEL,
	EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_FAILED,
	EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_FAILED_LABEL,
	EVT_SHOW_ACTIVATION_PRECONDITION,
	EVT_SHOW_ACTIVATION_PRECONDITION_CATEGORY,
	EVT_ACTION_SHOW,
	EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_PAUSING,
	EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_CANCELLATION,
	EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_UNKNOWN,
	EVT_SHOW_TEXT,
	EVT_FINALIZE_TRANSACTION,
	EVT_CLICKED_PAYMENT_METHOD_CATEGORY,
	EVT_CLICKED_PAYMENT_METHOD_FUNNEL_NAME,
	EVT_CLICKED_PAYMENT_METHOD_ACTION,
	EVT_CLICKED_PAYMENT_PROVIDER,
	EVT_CLICKED_PAYMENT_PROVIDER_CATEGORY,
	EVT_CLICKED_PAYMENT_PROVIDER_FUNNEL_NAME,
	EVT_CLICKED_PAYMENT_PROVIDER_ACTION,
	EVT_NOTIFICATION_PAYMENT_STILL_PENDING,
	EVT_NOTIFICATION_PAYMENT_STILL_PENDING_LABEL,
	EVT_NOTIFICATION_PAYMENT_STILL_PENDING_CATEGORY,
	EVT_VERIFY_CODE_ERROR,
	EVT_VERIFY_CODE_ERROR_CATEGORY,
	EVT_VERIFY_CODE_ERROR_ACTION,
	EVT_VERIFY_CODE_ERROR_EVENT,
	EVT_CLICKED_VERIFY_CODE,
	EVT_CLICKED_VERIFY_CODE_CATEGORY,
	EVT_CLICKED_VERIFY_CODE_ACTION,
	EVT_CLICKED_VERIFY_CODE_EVENT,
	EVT_ENTITLEMENT_VERIFY_INVALID,
	EVT_ENTITLEMENT_VERIFY_INVALID_EVENT,
	EVT_ENTITLEMENT_VERIFY_INVALID_CATEGORY,
	EVT_ENTITLEMENT_VERIFY_INVALID_ACTION,
	EVT_CLICKED_PPE_PAYMENT_METHOD,
	EVT_CLICKED_PPE_CHECKOUT_OPEN_INVOICE,
	EVT_CLICKED_PPE_PAYMENT_METHOD_ACTION,
	EVT_CLICKED_PPE_PAYMENT_PROVIDER,
	EVT_CLICKED_PPE_PAYMENT_PROVIDER_ACTION,
	EVT_CLICKED_PPE_CONTINUE_TRANSACTION,
	EVT_CLICKED_PPE_CONTINUE_TRANSACTION_ACTION,
	EVT_FINALIZE_PPE_TRANSACTION,
	EVT_TOGGLE_TIER,
} from '../constants/EventTypes';
import { getPresentationForTier } from '../helpers/tiers';
import { NO_TIER_ANALYTICS_NAME } from '../constants/Tiers';
import { ACTIVATE_PRECONDITIONS } from '../constants/Entitlements';
import { getCurrrentPlan } from '../helpers/state';

const ACTION_EVENT_MAP = {
	[DEVICE_ADD + '_SUCCESS']: EVT_DEVICE_ADD,
	[DEVICE_ADD + '_ERROR']: EVT_DEVICE_ADD_ERROR,
	[DEVICE_REMOVE + '_PENDING']: EVT_DEVICE_REMOVE_PENDING,
	[DEVICE_REMOVE + '_SUCCESS']: EVT_DEVICE_REMOVE,
};

let isSessionStarted = false;

const dataLayerPush = (data) => {
	if (__ANALYTICS__) {
		dataLayerPushIfPresent(data);
	} else if (__DEBUG__) {
		// eslint-disable-next-line no-console
		console.log('analytics push', JSON.stringify(data));
		dataLayerPushIfPresent(data);
	}

	function dataLayerPushIfPresent(data) {
		if (__CLIENT__) {
			dataLayer && dataLayer.push(data);
		}
	}
};

export default (store) => (dispatch) => (action) => {
	handleAnalyticsAction(
		action,
		store,
		dataLayerPush,
		isSessionStarted,
		() => (isSessionStarted = true)
	);
	return dispatch(action);
};

export function handleAnalyticsAction(
	action,
	store,
	dataLayerPush,
	isSessionStarted,
	onSessionStarted
) {
	const { type, payload } = action;
	const state = store.getState();

	const analyticsPayload = {
		pageUrl: state.application.path.href,
	};

	switch (type) {
		case ANALYTICS_PUSH: {
			if ('Genre' in payload) {
				payload.Genre = genresToGTMFormat(payload.Genre);
			}
			dataLayerPush(payload);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_SESSION_START}`: {
			if (!isSessionStarted) {
				onSessionStarted();
				const { tier, userId } = payload;
				const tierTitle = tier && getPresentationForTier(tier)?.title;
				Object.assign(analyticsPayload, {
					timestampStartSession: getTimestamp(),
					userid: userId,
					tier: tierTitle || NO_TIER_ANALYTICS_NAME,
				});
				dataLayerPush({
					...analyticsPayload,
					event: EVT_SESSION_START_EVENT_NAME,
				});
			}
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_INITIAL_PAGE}`: {
			const featureFlags = payload.featureFlags;
			const experimentFlags = payload.experimentFlags;
			Object.assign(analyticsPayload, {
				flags: createAnalyticsFlagsValue([...featureFlags, ...experimentFlags]),
			});
			dataLayerPush({
				...analyticsPayload,
				event: 'pageLoad',
				name: EVT_INITIAL_PAGE_NAME,
			});

			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_SUBSCRIPTION_CANCEL}`: {
			const analyticsData = {
				event: EVT_SUBSCRIPTION_CANCEL,
				cancellationAnswers: payload,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_TIER_OPTION_SELECT}`: {
			const analyticsData = {
				events: {
					category: EVT_ONBOARDING_CATEGORY,
					action: EVT_SELECT_TILE,
					label: payload.label,
				},
				event: EVT_SELECT_TILE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_TIER_OPTION_CONFIRM}`: {
			const analyticsData = {
				events: {
					category: EVT_ONBOARDING_CATEGORY,
					action: EVT_BUTTON_CLICK,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
				productName: payload.productName,
				productPosition: payload.productPosition,
				productPrice: payload.productPrice,
				productLabel: 'no label',
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_CANCEL}`: {
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_SUBSCRIPTION_CATEGORY,
					action: EVT_BUTTON_CLICK,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_SUCCESS}`: {
			const fromTier = getCurrrentPlan(store.getState());
			const fromTierName =
				(fromTier && getPresentationForTier(fromTier.planDescription.tierId)?.title) ||
				NO_TIER_ANALYTICS_NAME;
			const toTierName = getPresentationForTier(payload.newTierId)?.title || NO_TIER_ANALYTICS_NAME;
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_NOTIFICATION_CATEGORY,
					action: EVT_CHANGE_TIER_NOTIFICATION_SHOW,
					label: EVT_CHANGE_TIER_FUNNEL_NAME,
				},
				event: EVT_CHANGE_TIER_NOTIFICATION_SHOW,
				funnelName: EVT_CHANGE_TIER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_THREE,
				fromTier: fromTierName,
				tier: toTierName,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_ERROR}`: {
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_NOTIFICATION_CATEGORY,
					action: EVT_SHOW_ERROR,
					label: payload.label,
				},
				event: EVT_CHANGE_TIER_NOTIFICATION_SHOW,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_NOTIFICATION_HIDE}`: {
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_NOTIFICATION_CATEGORY,
					action: EVT_BUTTON_CLICK,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CHANGE_TIER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_FOUR,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_FROM_UNSUBSCRIBE_CLICK}`: {
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_PROFILE_CATEGORY,
					action: EVT_BUTTON_CLICK,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_CLICK}`: {
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_PROFILE_CATEGORY,
					action: EVT_BUTTON_CLICK,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CHANGE_TIER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_ONE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_SUBMIT}`: {
			const { productName, label, pos, price } = payload;
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_SUBSCRIPTION_CATEGORY,
					action: EVT_BUTTON_CLICK,
					label,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CHANGE_TIER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_TWO,
				productPosition: pos,
				productPrice: price,
				productLabel: 'no label',
				productName,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_EMAILVERIFIED_SHOW_TIERS}`: {
			const { title, price } = payload;
			const analyticsData = {
				events: {
					category: EVT_ONBOARDING_CATEGORY,
					label: title,
					action: EVT_SHOW_TIERS_LABEL,
				},
				event: EVT_SHOW_TIER_SUMMARY,
				productName: title,
				productPrice: price,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_EMAILVERIFIED_CHANGE_TIER}`: {
			const analyticsData = {
				events: {
					category: 'link',
					label: 'wijzigen',
					action: 'click',
				},
				event: EVT_LINK_CLICK,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_PAYMENT_REMINDER_SHOWN}`: {
			const analyticsData = {
				events: {
					category: EVT_PAYMENT_REMINDER_NOTIFICATION_CATEGORY,
					label: EVT_PAYMENT_REMINDER_SHOWN_LABEL,
					action: EVT_PAYMENT_REMINDER_SHOWN_ACTION,
				},
				event: EVT_SHOW_NOTIFICATION,
				funnelName: EVT_PAYMENT_REMINDER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_ONE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_PAYMENT_REMINDER_CLICKED_PAY}`: {
			const analyticsData = {
				events: {
					category: EVT_PAYMENT_REMINDER_NOTIFICATION_CATEGORY,
					label: payload.label,
					action: EVT_PAYMENT_REMINDER_CLICKED_PAY_ACTION,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_PAYMENT_REMINDER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_TWO,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_PAYMENT_REMINDER_SUCCESS_SHOWN}`: {
			const analyticsData = {
				events: {
					category: EVT_PAYMENT_REMINDER_NOTIFICATION_CATEGORY,
					label: EVT_PAYMENT_REMINDER_SUCCESS_SHOWN_LABEL,
					action: EVT_PAYMENT_REMINDER_SUCCESS_SHOWN_ACTION,
				},
				event: EVT_SHOW_NOTIFICATION,
				funnelName: EVT_PAYMENT_REMINDER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_THREE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_PAYMENT_REMINDER_SUCCESS_CLICKED_CLOSED}`: {
			const analyticsData = {
				events: {
					category: EVT_PAYMENT_REMINDER_NOTIFICATION_CATEGORY,
					label: payload.label,
					action: EVT_PAYMENT_REMINDER_SUCCESS_CLICKED_CLOSED_ACTION,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_PAYMENT_REMINDER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_FOUR,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE}`: {
			const analyticsData = {
				events: {
					category: EVT_CHANGE_TIER_NOTIFICATION_CATEGORY,
					action: EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE_ACTION,
					label: EVT_CHANGE_TIER_NOTIFICATION_DISCOUNTED_PRICE_LABEL,
				},
				event: EVT_CHANGE_TIER_NOTIFICATION_SHOW,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_PAYMENT_REMINDER_SHOWN_ERROR}`: {
			const { status, description, due_date } = payload;

			let label = EVT_PAYMENT_REMINDER_SHOWN_ERROR_LABEL;
			label = label.replace('{status}', status);
			label = label.replace('{message}', description);
			label = label.replace('{due_date}', due_date);

			const analyticsData = {
				events: {
					category: EVT_PAYMENT_REMINDER_NOTIFICATION_CATEGORY,
					label: label,
					action: EVT_PAYMENT_REMINDER_SHOWN_ERROR_ACTION,
				},
				event: EVT_SHOW_NOTIFICATION,
				funnelName: EVT_PAYMENT_REMINDER_FUNNEL_NAME,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CONCURRENCY_ERROR}`: {
			const analyticsData = {
				event: EVT_CONCURRENCY_ERROR,
				concurrency_limit: state.play.concurrencyLimit,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_NEWSLETTER_TOGGLE}`: {
			const analyticsData = {
				category: EVT_NEWSLETTER_CATEGORY,
				action: EVT_NEWSLETTER_TOGGLE,
				event: EVT_NEWSLETTER_TOGGLE,
				label: payload.label,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_PAY_OPEN_INVOICE}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_PAY_OPEN_INVOICE_CATEGORY,
					action: EVT_CLICKED_PAY_OPEN_INVOICE_ACTION,
					label: payload.label,
				},
				event: EVT_CLICKED_PAY_OPEN_INVOICE_EVENT,
				funnelName: EVT_CLICKED_PAY_OPEN_INVOICE_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_TWO,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_CHANGE_PAYMENT_DETAILS}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_CHANGE_PAYMENT_DETAILS_CATEGORY,
					action: EVT_CLICKED_CHANGE_PAYMENT_DETAILS_ACTION,
					label: payload.label,
				},
				event: EVT_CLICKED_CHANGE_PAYMENT_DETAILS_EVENT,
				funnelName: EVT_CLICKED_CHANGE_PAYMENT_DETAILS_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_ONE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_PAYMENT_METHOD}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_PAYMENT_METHOD_CATEGORY,
					action: EVT_CLICKED_PAYMENT_METHOD_ACTION,
					label: payload.label,
					planId: payload.planId ?? 'none',
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CLICKED_PAYMENT_METHOD_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_TWO,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_PAYMENT_PROVIDER}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_PAYMENT_PROVIDER_CATEGORY,
					action: EVT_CLICKED_PAYMENT_PROVIDER_ACTION,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CLICKED_PAYMENT_PROVIDER_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_THREE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_NOTIFICATION_PAYMENT_STILL_PENDING}`: {
			const analyticsData = {
				events: {
					category: EVT_NOTIFICATION_PAYMENT_STILL_PENDING_CATEGORY,
					action: EVT_ACTION_SHOW,
					label: EVT_NOTIFICATION_PAYMENT_STILL_PENDING_LABEL,
				},
				event: EVT_SHOW_NOTIFICATION,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_FLAG_ALLOCATED}`: {
			const analyticsData = {
				events: {
					category: EVT_FLAG_ALLOCATED_CATEGORY,
					action: payload.action,
					label: payload.label ?? 'none',
				},
				event: EVT_FLAG_ALLOCATED_EVENT,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_ONBOARDING_SUBMIT_GENDER}`: {
			const analyticsData = {
				events: {
					category: EVT_ONBOARDING_CATEGORY,
					action: EVT_ACTION_SUBMIT_GENDER,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_ONBOARDING_SKIP_GENDER}`: {
			const analyticsData = {
				events: {
					category: EVT_ONBOARDING_CATEGORY,
					action: EVT_LINK_CLICK,
					label: payload.label,
				},
				event: EVT_LINK_CLICK,
			};
			dataLayerPush(analyticsData);
			break;
		}
		case `${ANALYTICS_PUSH}_${EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_SUCCESS}`: {
			const analyticsData = {
				events: {
					category: EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_CATEGORY,
					action: EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_ACTION,
					label: EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_SUCCESS_LABEL,
				},
				event: EVT_SHOW_NOTIFICATION,
				funnelName: EVT_CLICKED_CHANGE_PAYMENT_DETAILS_FUNNEL_NAME,
				funnelStep: EVT_FUNNEL_STEP_THREE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_FAILED}`: {
			const analyticsData = {
				events: {
					category: EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_CATEGORY,
					action: EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_ACTION,
					label: EVT_SHOW_CONFIRMATION_BANK_ACCOUNT_FAILED_LABEL,
				},
				event: EVT_SHOW_NOTIFICATION,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_SHOW_ACTIVATION_PRECONDITION}`: {
			const labelMap = {
				[ACTIVATE_PRECONDITIONS.SUBSCRIPTION_WILL_BE_PAUSED]:
					EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_PAUSING,
				[ACTIVATE_PRECONDITIONS.SUBSCRIPTION_WILL_BE_CANCELLED]:
					EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_CANCELLATION,
			};
			const label =
				labelMap[payload.precondition] || EVT_SHOW_ACTIVATION_PRECONDITION_LABEL_UNKNOWN;
			const analyticsData = {
				events: {
					category: EVT_SHOW_ACTIVATION_PRECONDITION_CATEGORY,
					action: EVT_ACTION_SHOW,
					label,
				},
				event: EVT_SHOW_TEXT,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_FINALIZE_TRANSACTION}`: {
			const analyticsData = {
				event: EVT_FINALIZE_TRANSACTION,
				transactionId: payload.transactionId,
				products: {},
			};
			if (payload.rateplan?.toLowerCase() === 'voucher') {
				analyticsData.products = { sku: payload.planId, item_category: 'voucher' };
			} else {
				analyticsData.products = { sku: payload.rateplan };
			}
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_VERIFY_CODE_ERROR}`: {
			const analyticsData = {
				events: {
					category: EVT_VERIFY_CODE_ERROR_CATEGORY,
					action: EVT_VERIFY_CODE_ERROR_ACTION,
					label: payload.label,
					status: payload.status,
				},
				event: EVT_VERIFY_CODE_ERROR_EVENT,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_VERIFY_CODE}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_VERIFY_CODE_CATEGORY,
					action: EVT_CLICKED_VERIFY_CODE_ACTION,
					label: payload.partnerSlug,
				},
				event: EVT_CLICKED_VERIFY_CODE_EVENT,
				transactionId: payload.transactionId,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_ENTITLEMENT_VERIFY_INVALID}`: {
			const analyticsData = {
				events: {
					category: EVT_ENTITLEMENT_VERIFY_INVALID_CATEGORY,
					action: EVT_ENTITLEMENT_VERIFY_INVALID_ACTION,
					label: `${payload.error}; ${payload.errorPageUrl}`,
				},
				event: EVT_ENTITLEMENT_VERIFY_INVALID_EVENT,
				partner: payload.partner,
			};

			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_PPE_PAYMENT_METHOD}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_PPE_CHECKOUT_OPEN_INVOICE,
					action: EVT_CLICKED_PPE_PAYMENT_METHOD_ACTION,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CLICKED_PPE_CHECKOUT_OPEN_INVOICE,
				funnelStep: EVT_FUNNEL_STEP_TWO,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_PPE_PAYMENT_PROVIDER}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_PPE_CHECKOUT_OPEN_INVOICE,
					action: EVT_CLICKED_PPE_PAYMENT_PROVIDER_ACTION,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CLICKED_PPE_CHECKOUT_OPEN_INVOICE,
				funnelStep: EVT_FUNNEL_STEP_THREE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_CLICKED_PPE_CONTINUE_TRANSACTION}`: {
			const analyticsData = {
				events: {
					category: EVT_CLICKED_PPE_CHECKOUT_OPEN_INVOICE,
					action: EVT_CLICKED_PPE_CONTINUE_TRANSACTION_ACTION,
					label: payload.label,
				},
				event: EVT_BUTTON_CLICK,
				funnelName: EVT_CLICKED_PPE_CHECKOUT_OPEN_INVOICE,
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_FINALIZE_PPE_TRANSACTION}`: {
			const analyticsData = {
				event: EVT_FINALIZE_TRANSACTION,
				products: {
					sku: 'unpaid-invoice',
				},
			};
			dataLayerPush(analyticsData);
			break;
		}

		case `${ANALYTICS_PUSH}_${EVT_TOGGLE_TIER}`: {
			const analyticsData = {
				event: EVT_TOGGLE_TIER,
				click_text: payload.click_text,
			};
			dataLayerPush(analyticsData);
			break;
		}

		default: {
			break;
		}
	}

	if (type in ACTION_EVENT_MAP) {
		store.dispatch({
			type: ANALYTICS_PUSH,
			payload: {
				event: ACTION_EVENT_MAP[type],
				...analyticsPayload,
			},
		});
	}
}
