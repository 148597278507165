import { getEndpointUrl } from '../../config';
import mutationRevokeCancellation from '../../graphql/mutationRevokeCancellation.graphql';
import { dispatchApiError, dispatchApiSuccess } from '../../helpers/redux';
import { SUBSCRIPTION_REACTIVATE } from '../../constants/ActionTypes';
import { reportError } from '../../helpers/realUserMonitoring';
import { fetchSubscriptionTier } from '../profileActions';

export function createReactivateSubscriptionAction(subscriptionService) {
	return function reactivateSubscriptionThunk(order_name) {
		return (dispatch) => {
			const action = { payload: { order_name } };
			return new Promise((resolve, reject) => {
				subscriptionService(
					{
						url: getEndpointUrl('graphql_path'),
						mutation: mutationRevokeCancellation,
					},
					onReactivateSubscriptionSuccess,
					onReactivateSubscriptionError
				);

				function onReactivateSubscriptionError(err) {
					dispatchApiError({ dispatch, type: SUBSCRIPTION_REACTIVATE, action, err });
					reportError(err);
					resolve();
				}

				function onReactivateSubscriptionSuccess(result) {
					if (result.data?.revokeCancellation?.__typename === 'RevokeCancellationSuccess') {
						dispatchApiSuccess({
							dispatch,
							type: SUBSCRIPTION_REACTIVATE,
							action,
							payload: result,
						});
						dispatch(fetchSubscriptionTier()).then(resolve).catch(reject);
					} else {
						dispatchApiError({
							dispatch,
							type: SUBSCRIPTION_REACTIVATE,
							action,
							err: { message: result.reason },
						});
						resolve();
					}
				}
			});
		};
	};
}
