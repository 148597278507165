import { useEffect, useState } from 'react';
import { getEndpointUrl } from '../config';
import { accountService } from '../api';
import { reportError } from '../helpers/realUserMonitoring';
import { hasValidVideolandCookie } from '../helpers/authentication';
import { session } from '../helpers/storage';
import queryUserAccountPropsAuth from '../graphql/queryUserAccountPropsAuthenticated.graphql';
import queryFeatureFlags from '../graphql/queryFeatureFlags.graphql';
import { useLocation } from 'react-router';
import { ROUTE_LOGOUT } from '../constants/Routes';

/**
 * This hooks is responsible for fetching account state from back-end
 * and resolving 'conflicts' with front-end state
 * this supports both authenticated and unauthenticated users
 * as unauthenticated users can also have state coming from back-end (e.g. feature flagging)
 * @param isPromoPage
 * @returns {{isLoading: boolean, isError: boolean, showPromo: boolean, isPristine: boolean}}
 */
export const useVideolandAccountState = createUseVideolandAccountState(
	useLocation,
	hasValidVideolandCookie,
	accountService,
	reportError,
	session
);

export function createUseVideolandAccountState(
	useLocation,
	hasValidVideolandCookie,
	accountService,
	reportError
) {
	return function useVideolandAccountState(isPromoPage = true) {
		const [isBlackFriday, setIsBlackFriday] = useState();
		const [showPromo, setShowPromo] = useState(null);
		const [isLoading, setIsLoading] = useState(true);
		const [isError, setIsError] = useState(null);
		const [isPristine, setIsPristine] = useState(true);
		const isAuthenticatedUser = hasValidVideolandCookie();
		const { pathname } = useLocation();

		useEffect(() => {
			let isMounted = true;

			if (isAuthenticatedUser && pathname !== ROUTE_LOGOUT) {
				getAccountStateAuthenticated();
			} else {
				// If unauthenticated user, the page variable determines the promo state
				setShowPromo(isPromoPage);
				setIsPristine(false);
				getFeatureFlags();
			}

			function getAccountStateAuthenticated() {
				accountService(
					{
						url: getEndpointUrl('graphql_path'),
						query: queryUserAccountPropsAuth,
						fetchPolicy: 'no-cache',
					},
					onAuthenticatedSuccess,
					onError
				);
			}

			function getFeatureFlags() {
				if (pathname !== ROUTE_LOGOUT) {
					accountService(
						{
							url: getEndpointUrl('graphql_path'),
							query: queryFeatureFlags,
							fetchPolicy: 'no-cache',
						},
						onFeatureFlagsSuccess
					);
				}
			}

			function onAuthenticatedSuccess({ data }) {
				if (isMounted) {
					setShowPromo(data?.me?.isPromo);
					setIsBlackFriday(data?.me?.featureFlags?.isBlackFriday === true);
					setIsLoading(false);
					setIsError(false);
					setIsPristine(false);
				}
			}

			function onFeatureFlagsSuccess({ data }) {
				if (isMounted) {
					setIsBlackFriday(data?.featureFlags?.isBlackFriday === true);
					setIsLoading(false);
					setIsError(false);
				}
			}

			return () => {
				isMounted = false;
			};
		}, [isAuthenticatedUser, isPromoPage, pathname]);

		function onError(e) {
			reportError(e);
			setIsError(true);
			setIsLoading(false);
		}

		return {
			isBlackFriday,
			showPromo,
			isAuthenticatedUser,
			isLoading,
			isError,
			isPristine,
		};
	};
}
